import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  playableSelector,
  playheadIsPausedState,
  playheadIsStoppedState,
  playheadState,
} from '@store/atoms/PlayheadState';
import { timelineDurationState } from '@store/selectors/EditSelectors';

import useSoundtrack from '@hooks/useSoundtrack';

export const TimelinePlaybackContext = createContext();
export const useTimelinePlaybackContext = () => useContext(TimelinePlaybackContext);

export function TimelinePlaybackProvider({ children }) {
  const [isPaused, setIsPaused] = useRecoilState(playheadIsPausedState);
  const [isStopped, setIsStopped] = useRecoilState(playheadIsStoppedState);
  const [playhead, setPlayhead] = useRecoilState(playheadState);
  const isPlayable = useRecoilValue(playableSelector);
  const timelineDuration = useRecoilValue(timelineDurationState);

  const soundtrack = useSoundtrack();

  const requestAnimationFrameRef = useRef();
  const initialPlayheadTimeRef = useRef();
  const fpsThrottleRef = useRef();

  const fps = 60;
  const fpsInterval = 1000 / fps;

  const animate = () => {
    const now = Date.now();
    const playheadPosition = now - initialPlayheadTimeRef.current;
    const elapsed = now - fpsThrottleRef.current;

    if (elapsed > fpsInterval) {
      setPlayhead(playheadPosition / 1000);
      fpsThrottleRef.current = now - (elapsed % fpsInterval);
    }

    requestAnimationFrameRef.current = requestAnimationFrame(animate);
  };

  const handlePlay = () => {
    if (timelineDuration <= 0) {
      return;
    }

    if (isStopped) {
      initialPlayheadTimeRef.current = Date.now();
    }

    if (isPaused) {
      initialPlayheadTimeRef.current = Date.now() - playhead * 1000;
    }

    setIsStopped(false);
    setIsPaused(false);
    soundtrack?.play({ start: playhead, end: timelineDuration });
    fpsThrottleRef.current = Date.now();

    requestAnimationFrameRef.current = requestAnimationFrame(animate);
  };

  const handlePause = () => {
    setIsPaused(true);
    setIsStopped(false);
    soundtrack?.pause();
    cancelAnimationFrame(requestAnimationFrameRef.current);
  };

  const handlePlayPause = () => (isPaused || isStopped ? handlePlay() : handlePause());

  const handleStop = () => {
    setIsPaused(false);
    setIsStopped(true);
    cancelAnimationFrame(requestAnimationFrameRef.current);
    setPlayhead(0);
    soundtrack?.stop();

    // manipulating playheadPosition so it plays from the playhead position
    handlePause();
  };

  useEffect(() => {
    if (timelineDuration > 0 && playhead >= timelineDuration) {
      setIsPaused(false);
      setIsStopped(true);
      cancelAnimationFrame(requestAnimationFrameRef.current);
      setPlayhead(timelineDuration);
      soundtrack?.stop();
    }
  }, [playhead, setIsPaused, setIsStopped, setPlayhead, timelineDuration]);

  useEffect(() => {
    cancelAnimationFrame(requestAnimationFrameRef.current);

    // manipulating playheadPosition so it plays from the playhead position
    handlePause();

    return () => handleStop();
  }, []);

  const contextState = useMemo(() => {
    return {
      isPaused,
      isStopped,
      playhead,
      isPlayable,
      isPlaying: !isPaused && !isStopped,
      timelineDuration,
      handlePlay,
      handlePause,
      handleStop,
      handlePlayPause,
      setPlayhead,
    };
  }, [isPaused, isStopped, playhead, isPlayable, timelineDuration]);

  return <TimelinePlaybackContext.Provider value={contextState}>{children}</TimelinePlaybackContext.Provider>;
}
