/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number; alpha: number; }>}
 */
export const zoomTransitionInKeyframe = (start, end, properties) => {
  const { clip, canvas } = properties;

  return {
    from: {
      scale: canvas.width / (clip.width / 2),
      alpha: 0,
    },
    to: {
      scale: clip.scale,
      alpha: 1,
    },
    start,
    end,
    interpolation: 'easeIn',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number; alpha: number; }>}
 */
export const zoomTransitionOutKeyframe = (start, end, properties) => {
  const { clip, canvas } = properties;

  return {
    from: {
      scale: clip.scale,
      alpha: 1,
    },
    to: {
      scale: canvas.width / (clip.width / 2),
      alpha: 0,
    },
    start,
    end,
    interpolation: 'easeOut',
  };
};
