import { getTemplate, transformData } from '@api/services/template';
import { transformTemplate } from '@api/transform/utils/template';
import AssetLoader from '@components/assetLoader/AssetLoader';
import '@css/Editor.css';
import TemplateConfiguration from '@feature/studio/configuration/TemplateConfiguration';
import Workspace from '@feature/studio/workspace/Workspace';
import { assetLoaderIsLoading } from '@store/atoms/AssetsState';
import { activeClipState } from '@store/atoms/ClipState';
import { useHydrateStoreFromTemplate, viewState } from '@store/atoms/EditState';
import {
  clipChangeState,
  interactiveState,
  timelineState,
  styleState,
  originState,
} from '@store/atoms/InteractionState';
import { templateLoadingState } from '@store/atoms/TemplateState';
import { errorState } from '@store/atoms/UIState';
import { derivedJsonState } from '@store/selectors/EditSelectors';
import { useResetStudio } from '@store/selectors/StudioSelectors';
import { Suspense, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState, selector } from 'recoil';

function Editor() {
  const hydrateStore = useHydrateStoreFromTemplate();
  const resetStudio = useResetStudio();

  const setAssetsLoading = useSetRecoilState(assetLoaderIsLoading);
  const [templateLoading, setTemplateLoading] = useRecoilState(templateLoadingState);
  const resetActiveClip = useResetRecoilState(activeClipState);
  const setErrorMessage = useSetRecoilState(errorState);
  const templateJson = useRecoilValue(derivedJsonState());
  const clipChanged = useRecoilState(clipChangeState);
  const style = useRecoilValue(styleState);
  const setInteractiveState = useSetRecoilState(interactiveState);
  const setInteractiveTimelineState = useSetRecoilState(timelineState);
  const setStyle = useSetRecoilState(styleState);
  const origin = useRecoilValue(originState);
  const setOrigin = useSetRecoilState(originState);

  const [showConfiguration, setShowConfiguration] = useState(false);

  const getTemplateData = async () => {
    setTemplateLoading(true);
    // setTemplateId(id);
    setAssetsLoading(true);
    try {
      const data = await getTemplate();
      hydrateStore(data.template);
      resetActiveClip();
    } catch (error) {
      setErrorMessage({ message: error.message });
    }
    setTemplateLoading(false);
  };

  const showNewPreview = async (data) => {
    setTemplateLoading(true);
    setAssetsLoading(true);
    try {
      const template = await transformData(data);
      hydrateStore(template.template);
      resetActiveClip();
      return template;
    } catch (error) {
      setErrorMessage({ message: error.message });
    }
    setTemplateLoading(false);
  };

  useEffect(() => {
    if (origin) {
      const parsedJson = JSON.parse(templateJson);
      // console.log('parsedJson', parsedJson);
      document.documentElement.style.setProperty('--primary', style.primaryColor);
      document.documentElement.style.setProperty('--primary-dark', style.secondaryColor);
      document.documentElement.style.setProperty('--asset-color-text', style.secondaryColor);
      document.documentElement.style.setProperty('--asset-color-image', style.secondaryColor);
      document.documentElement.style.setProperty('--asset-color-video', style.secondaryColor);
      document.documentElement.style.setProperty('--asset-color-audio', style.secondaryColor);
      document.documentElement.style.setProperty('--asset-color-mask', style.primaryColor);
      window.parent.postMessage(parsedJson, origin);
    }
  }, [clipChanged, origin]);

  useEffect(() => {
    const handleMessage = async (event) => {
      try {
        const data = event.data;
        if (data.origin && data.origin !== undefined) {
          // console.log('Origin received:', data.origin);
          await setOrigin(data.origin);
        }

        if (data.template) {
          await showNewPreview(data.template);
        }

        if (data.options) {
          if (typeof data.options.interactive === 'boolean') {
            await setInteractiveState(data.options.interactive);
          } else {
            console.error('interactive must be a boolean');
          }
          if (typeof data.options.timeline === 'boolean') {
            await setInteractiveTimelineState(data.options.timeline);
          } else {
            console.error('timeline must be a boolean');
          }
          if (data.options.style) {
            setStyle(data.options.style);
          }
        }
      } catch (error) {
        console.error('Error parsing message data:', error);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [setOrigin, showNewPreview]);

  useEffect(() => {
    getTemplateData();
    return () => {
      resetStudio();
    };
  }, []);

  return (
    <div className='editor'>
      <Suspense fallback={<div />}>
        <div className='editor__main'>
          <AssetLoader />

          {showConfiguration && <TemplateConfiguration onHide={() => setShowConfiguration(false)} />}
          <Workspace templateLoading={templateLoading} />
        </div>
      </Suspense>
    </div>
  );
}

export default Editor;
