/**
 * @type {(start: number, end: number, progress: number) => number}
 */
export const bezierInterpolation = (start, end, progress) => {
  const controlPoint1 = start + (end - start) / 3;
  const controlPoint2 = end - (end - start) / 3;

  const progressValue = progress;
  const inverseProgress = 1 - progressValue;
  const progressSquared = progressValue * progressValue;
  const inverseProgressSquared = inverseProgress * inverseProgress;
  const inverseProgressCubed = inverseProgressSquared * inverseProgress;
  const progressCubed = progressSquared * progressValue;

  const interpolatedStart = start * inverseProgressCubed;
  const interpolatedControlPoint1 = controlPoint1 * 3 * inverseProgressSquared * progressValue;
  const interpolatedControlPoint2 = controlPoint2 * 3 * inverseProgress * progressSquared;
  const interpolatedEnd = end * progressCubed;

  return interpolatedStart + interpolatedControlPoint1 + interpolatedControlPoint2 + interpolatedEnd;
};
