import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import '@css/ColorPicker.css';

function ColorPicker({ className, name, handleEvent, value }) {
  const [color, setColor] = useState(value);
  const classes = ['color-picker', className, !value && 'isTransparent'].filter(Boolean).join(' ');

  const handleDebounce = (event) => {
    handleEvent(event);
  };

  const debounceColorChange = useCallback(debounce(handleDebounce, 100), [handleEvent]);

  const onChange = (event) => {
    setColor(event.target.value);
    debounceColorChange(event);
  };

  useEffect(() => {
    if (value !== color) {
      setColor(value);
    }
  }, [value]);

  return (
    <div className={classes}>
      <Form.Control name={name} type="color" value={color} onChange={onChange} />
    </div>
  );
}

export default ColorPicker;
