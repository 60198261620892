const BOUNDING_BOX_HEIGHT_PIXELS = 46;
const BOUNDING_BOX_WIDTH_PIXELS = 300;
const MAX_WIDTH = 7680;
const MAX_HEIGHT = 7680;
const TEXT = 'HELLO WORLD';
const COLOR = '#ffffff';
const FONT_SIZE = '32';
const FONT_FAMILY = 'Montserrat ExtraBold';
const TEXT_ALIGN = 'center';
const TEXT_SCALE = false;

module.exports = {
  BOUNDING_BOX_HEIGHT_PIXELS,
  BOUNDING_BOX_WIDTH_PIXELS,
  MAX_WIDTH,
  MAX_HEIGHT,
  TEXT,
  COLOR,
  FONT_SIZE,
  FONT_FAMILY,
  TEXT_ALIGN,
  TEXT_SCALE,
};
