import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { activeClipState } from '@store/atoms/ClipState';
import { clipChangeState } from '@store/atoms/InteractionState';

import {
  calculateDimensions,
  calculatePosition,
  calculateResize,
  calculateScale,
} from '@utils/editor/assetCalculations';
import calculateHandlePosition from '@utils/editor/editFrameCalculations';
import setPrecision from '@utils/math/setPrecision';

function useMovableAsset(params) {
  const { id, assetHeight, assetWidth, canvasHeight, canvasWidth, clip, setClip, asset, setAsset, spriteRef } = params;
  const [originalAssetWidth, setOriginalAssetWidth] = useState(assetWidth);
  const [originalAssetHeight, setOriginalAssetHeight] = useState(assetHeight);
  const [originalCanvasWidth, setOriginalCanvasWidth] = useState(canvasWidth);
  const [originalCanvasHeight, setOriginalCanvasHeight] = useState(canvasHeight);
  const [isMoving, setIsMoving] = useState(false);
  const [isScaling, setIsScaling] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [fit, setFit] = useState(clip?.fit || 'crop');
  const [scale, setScale] = useState(clip?.scale || 1);
  const [dimensions, setDimensions] = useState(
    calculateDimensions({
      assetHeight,
      assetWidth,
      canvasHeight,
      canvasWidth,
      dimensions: asset?.width ? { width: asset.width, height: asset?.height } : undefined,
      fit,
      scale: clip?.scale || 1,
    })
  );
  const [position, setPosition] = useState(
    calculatePosition({
      assetHeight,
      assetWidth,
      canvasHeight,
      canvasWidth,
      positionType: clip?.position || 'center',
      positionX: clip?.offset?.x,
      positionY: clip?.offset?.y,
      scale,
    })
  );
  const setActiveClip = useSetRecoilState(activeClipState);
  const setClipChangeState = useSetRecoilState(clipChangeState);

  const [xMoveOrigin, setXMoveOrigin] = useState(0);
  const [yMoveOrigin, setYMoveOrigin] = useState(0);
  const [positionOrigin, setPositionOrigin] = useState(position);
  const [xResizeOrigin, setXResizeOrigin] = useState(0);
  const [yResizeOrigin, setYResizeOrigin] = useState(0);
  const [scaleOrigin, setScaleOrigin] = useState(scale);
  const [xScaleOrigin, setXScaleOrigin] = useState(0);
  const [yScaleOrigin, setYScaleOrigin] = useState(0);
  const [dimensionsOrigin, setDimensionsOrigin] = useState(dimensions);
  const [bounds, setBounds] = useState(
    calculateHandlePosition({
      dimensions,
      position,
    })
  );

  const setAssetPosition = useCallback(() => {
    const updatedDimensions = calculateDimensions({
      assetHeight,
      assetWidth,
      canvasHeight,
      canvasWidth,
      fit: clip?.fit || 'crop',
      scale: clip?.scale || 1,
    });

    const updatedPosition = calculatePosition({
      assetHeight: updatedDimensions.height,
      assetWidth: updatedDimensions.width,
      canvasHeight,
      canvasWidth,
      positionType: clip?.position || 'center',
      positionX: clip?.offset?.x,
      positionY: clip?.offset?.y,
      scale: updatedDimensions.scale,
    });

    const handlePosition = calculateHandlePosition({ dimensions: updatedDimensions, position: updatedPosition });

    setDimensions(updatedDimensions);
    setPosition(updatedPosition);
    setBounds(handlePosition);
    setScale(clip?.scale || 1);
  }, [assetHeight, assetWidth, canvasHeight, canvasWidth, clip?.offset, clip?.position, clip?.scale, clip?.fit]);

  useEffect(() => {
    if ((assetHeight && assetHeight !== originalAssetHeight) || (assetWidth && assetWidth !== originalAssetWidth)) {
      const updatedDimensions = calculateDimensions({
        assetHeight,
        assetWidth,
        canvasHeight,
        canvasWidth,
        fit,
        scale: clip?.scale || 1,
      });

      const handlePosition = calculateHandlePosition({ dimensions: updatedDimensions, position });

      setDimensions(updatedDimensions);
      setBounds(handlePosition);
      setScale(clip?.scale || 1);
      setOriginalAssetWidth(assetWidth);
      setOriginalAssetHeight(assetHeight);
    }
  }, [assetWidth, assetHeight, originalAssetWidth, originalAssetHeight]);

  useEffect(() => {
    if (
      (canvasWidth > 0 && canvasWidth !== originalCanvasWidth) ||
      (canvasHeight > 0 && canvasHeight !== originalCanvasHeight)
    ) {
      setAssetPosition();
      setOriginalCanvasWidth(canvasWidth);
      setOriginalCanvasHeight(canvasHeight);
    }
  }, [canvasWidth, canvasHeight, originalCanvasHeight, originalCanvasWidth]);

  useEffect(() => {
    if (clip?.scale && clip?.scale !== scale) {
      setAssetPosition();
      setScale(clip.scale);
    }
  }, [clip?.scale]);

  useEffect(() => {
    setAssetPosition();
  }, [clip.offset, setAssetPosition]);

  useEffect(() => {
    setAssetPosition();
    setFit(clip.fit || 'crop');
  }, [clip.fit, setAssetPosition]);

  const handlePositionGrab = (event) => {
    const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
    setXMoveOrigin(spriteRef.current.x + mousePosition.x - position.x);
    setYMoveOrigin(spriteRef.current.y + mousePosition.y - position.y);
    setPositionOrigin({ ...position });
    setIsMoving(true);
    setActiveClip(id);
  };

  const handlePositionDrop = () => {
    const xPosition = position.x - canvasWidth / 2;
    const yPosition = position.y - canvasHeight / 2;
    const updatedOffset = {
      x: setPrecision(xPosition / canvasWidth, 3),
      y: setPrecision(-yPosition / canvasHeight, 3),
    };

    setClipChangeState(true);

    setClip({
      ...clip,
      offset: updatedOffset,
      position: 'center',
    });
    setIsMoving(false);
  };

  const handlePositionDrag = (event) => {
    if (isMoving) {
      const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
      const updatedPosition = {
        x: mousePosition.x - xMoveOrigin + positionOrigin.x,
        y: mousePosition.y - yMoveOrigin + positionOrigin.y,
      };
      setPosition({
        x: updatedPosition.x,
        y: updatedPosition.y,
      });
      setBounds(
        calculateHandlePosition({
          dimensions,
          position: {
            x: updatedPosition.x,
            y: updatedPosition.y,
          },
        })
      );
    }
  };

  const handleResizeGrab = (event) => {
    const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
    setXResizeOrigin(mousePosition.x);
    setYResizeOrigin(mousePosition.y);
    setPositionOrigin({ ...position });
    setDimensionsOrigin({ ...dimensions });
    setIsResizing(true);
    setActiveClip(id);
  };

  const handleResizeDrag = (event, handlePosition) => {
    if (isResizing) {
      const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);

      const { resizedPosition, resizedHeight, resizedWidth } = calculateResize({
        assetHeight,
        assetWidth,
        position: positionOrigin,
        mousePosition,
        handlePosition,
        xResizeOrigin,
        yResizeOrigin,
      });

      const updatedDimensions = {
        ...dimensions,
        height: resizedHeight,
        width: resizedWidth,
      };

      const updatedHandlePosition = calculateHandlePosition({
        dimensions: updatedDimensions,
        position: resizedPosition,
      });

      setDimensions(updatedDimensions);
      setPosition(resizedPosition);
      setBounds(updatedHandlePosition);
    }
  };

  const handleResizeDrop = () => {
    setAsset({
      width: setPrecision(dimensions.width, 3),
      height: setPrecision(dimensions.height, 3),
    });
    setIsResizing(false);
  };

  const handleScaleDrop = () => {
    setClip({
      ...clip,
      scale: setPrecision(scale, 3),
    });
    setIsScaling(false);
  };

  const handleScaleDrag = (event, dragPosition) => {
    if (isScaling) {
      const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);

      const { resizedHeight, resizedScale, resizedWidth } = calculateScale({
        assetHeight,
        assetWidth,
        dimensions,
        mousePosition,
        position: dragPosition,
        scaleOrigin,
        dimensionsOrigin,
        xScaleOrigin,
        yScaleOrigin,
        fit,
        canvasHeight,
        canvasWidth,
      });

      const updatedDimensions = {
        ...dimensions,
        height: setPrecision(resizedHeight, 3),
        width: setPrecision(resizedWidth, 3),
      };

      const handlePosition = calculateHandlePosition({ dimensions: updatedDimensions, position });

      let adjustedScale = resizedScale / dimensions.aspectRatio;

      if (fit === 'none') {
        adjustedScale = resizedScale;
      }

      setDimensions(updatedDimensions);
      setBounds(handlePosition);
      setScale(adjustedScale);
    }
  };

  const handleScaleGrab = (event) => {
    const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
    setXScaleOrigin(mousePosition.x);
    setYScaleOrigin(mousePosition.y);
    setDimensionsOrigin({ ...dimensions });
    setScaleOrigin(dimensions.width / assetWidth);
    setIsScaling(true);
    setActiveClip(id);
  };

  return {
    bounds,
    canvasWidth,
    canvasHeight,
    dimensions,
    handlePositionGrab,
    handlePositionDrop,
    handlePositionDrag,
    handleResizeDrag,
    handleResizeDrop,
    handleResizeGrab,
    handleScaleDrag,
    handleScaleDrop,
    handleScaleGrab,
    isMoving,
    position,
    scale,
  };
}

export default useMovableAsset;
