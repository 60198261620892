import { transformClip } from '@api/transform/utils/clips';

export const transformTracks = (mergeData) => async (tracks) => {
  const tracksPromises = (tracks || []).map(async (track) => {
    const clipsPromises = (track.clips || []).map((clip) => transformClip(clip, mergeData));
    const transformedClips = await Promise.all(clipsPromises);
    return { ...track, clips: transformedClips };
  });
  const tracksTransformed = await Promise.all(tracksPromises);
  return tracksTransformed;
};
