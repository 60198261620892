import Gtm from '@analytics/google-tag-manager';
import Segment from '@analytics/segment';
import config from '@config';
import Analytics from 'analytics';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { authenticatedUser } from '@store/atoms/AuthState';
import stageState from '@store/atoms/StageState';

import capitaliseWords from '@utils/text/capitaliseWords';

const analytics = Analytics({
  app: 'shotstack',
  plugins: [
    Gtm({
      containerId: config.analytics.gtm.id,
    }),
    Segment({
      writeKey: config.analytics.segment.writeKey,
    }),
  ],
});

const AnalyticsContext = createContext();

const defaultParameters = (stage) => {
  return {
    Environment: capitaliseWords(stage),
    Platform: 'Dashboard',
  };
};

export function AnalyticsProvider(props) {
  const authUser = useRecoilValue(authenticatedUser);
  const [user, setUser] = useState();
  const stage = useRecoilValue(stageState);

  const { children } = { ...props };

  const identifyUser = (userDetails) => {
    analytics.identify(userDetails.auth0_id, {
      email: userDetails?.email,
      name: userDetails?.name,
      plan: userDetails?.plans?.v1,
    });

    if (userDetails.stages && userDetails.stages.length) {
      const keys = Object.keys(userDetails.stages);
      keys.forEach((key) => {
        analytics.alias(userDetails?.auth0_id, userDetails?.stages[key]);
      });
    }
  };

  useEffect(() => {
    if (authUser && !user) {
      identifyUser(authUser);
      setUser(authUser);
    }
  }, [authUser, user]);

  const trackEvent = (name, data) => {
    const baseParameters = defaultParameters(stage);
    analytics.track(name, {
      ...baseParameters,
      ...data,
    });
  };

  const contextState = useMemo(() => {
    return {
      identifyUser,
      trackEvent,
    };
  }, [stage]);

  return <AnalyticsContext.Provider value={contextState}>{children}</AnalyticsContext.Provider>;
}

export const useAnalytics = () => useContext(AnalyticsContext);
