import { atom, atomFamily } from 'recoil';

export const assetsFamily = atomFamily({
  key: 'assetsFamilyState',
  default: {},
});

export const clipsAssetsFamily = atomFamily({
  key: 'clipsAssetsFamilyState',
  default: {},
});

export const assetLoaderIsLoading = atom({
  key: 'assetLoaderIsLoading',
  default: false,
});

export const assetLoaderError = atom({
  key: 'assetLoaderError',
  default: false,
});

export const assetLoaderProgress = atom({
  key: 'assetLoaderProgress',
  default: 0,
});

export const assetLoaderRegistry = atom({
  key: 'assetLoaderRegistry',
  default: [],
});
