import { memo } from 'react';

import '@css/Workspace.css';

import { TimelinePlaybackProvider } from '@context/TimelinePlaybackProvider';

import WorkspaceCanvas from '@feature/studio/workspace/WorkspaceCanvas';
import WorkspaceTimelineReactable from '@feature/studio/workspace/WorkspaceTimelineReactable';

import { timelineState } from '@store/atoms/InteractionState';

import { useRecoilValue } from 'recoil';

function Workspace({ templateLoading }) {
  const  timeline = useRecoilValue(timelineState);
  return (
    <TimelinePlaybackProvider>
      <div className="workspace" data-testid="studio-workspace">
        <div className="workspace__main">
          <div className="workspace__left">
            <WorkspaceCanvas />
          </div>
        </div>
        { timeline && (
          <WorkspaceTimelineReactable templateLoading={templateLoading} />
        )}
      </div>
    </TimelinePlaybackProvider>
  );
}

export default memo(Workspace);
