import { bezierInterpolation } from '@animation/interpolation/bezier';
import { easeInInterpolation, easeOutInterpolation } from '@animation/interpolation/cubic';
import { linearInterpolation } from '@animation/interpolation/linear';

/**
 * @typedef {'linear' | 'easeIn' | 'easeOut' | 'bezier'} Interpolation
 */

/**
 * @template T
 *
 * @typedef {{
 *  from: T
 *  to: T
 *  start: number;
 *  end: number;
 *  interpolation?: import('@animation/keyframe').Interpolation;
 * }} Keyframe<T>
 */

/**
 * @typedef {(interpolation: Interpolation) => (start: number, end: number, progress: number) => number}
 */
export const getInterpolator = (interpolation = 'linear') => {
  switch (interpolation) {
    case 'linear':
      return linearInterpolation;
    case 'easeIn':
      return easeInInterpolation;
    case 'easeOut':
      return easeOutInterpolation;
    case 'bezier':
      return bezierInterpolation;
    default:
      return linearInterpolation;
  }
};

/**
 * @type {(value: number) => number}
 */
const roundKeyframeValue = (value) => Math.round(value * 10000) / 10000;

/**
 * @type {(keyframe: Keyframe<{}>, time: number) => Record<string, number>}
 */
export const interpolateKeyframe = (keyframe, time) => {
  const { from, to, start, end, interpolation } = keyframe;

  const interpolate = getInterpolator(interpolation);

  /** @type {Record<string, number>} */
  const keyframeValues = {};

  Object.keys(from).forEach((key) => {
    const fromValue = from[key];
    const toValue = to[key] ?? fromValue;

    if (![fromValue, toValue].every((value) => typeof value === 'number')) return;

    const keyframeProgress = (time - start) / (end - start);
    keyframeValues[key] = roundKeyframeValue(interpolate(fromValue, toValue, keyframeProgress));
  });

  return keyframeValues;
};
