import { canvasState, stageState } from '@store/atoms/CanvasState';
import { outputDimensionsSelector } from '@store/studio/Output';
import { isNumber } from '@utils/isNumber';
import { selectorFamily } from 'recoil';

export const canvasStageState = selectorFamily({
  key: 'canvasStage/dimensions',
  get:
    () =>
    ({ get }) => {
      const canvas = get(canvasState);
      const stage = get(stageState);

      const values = [...Object.values(canvas), ...Object.values(stage)];
      const ready = values.filter((value) => !isNumber(value)).length === 0;

      return {
        ...canvas,
        stage,
        ready,
      };
    },
  set:
    () =>
    ({ get, set }, { containerWidth, containerHeight }) => {
      const output = get(outputDimensionsSelector);

      // const containerMinPadding = 40;
      const containerMinPadding = 0;
      const targetStageHeight = containerHeight - containerMinPadding * 2;
      const targetStageWidth = containerWidth - containerMinPadding * 2;
      const outputScaleToStage = Math.min(targetStageHeight / output.size.height, targetStageWidth / output.size.width);

      set(canvasState, {
        width: output.size.width,
        height: output.size.height,
      });

      set(stageState, {
        width: outputScaleToStage * output.size.width,
        height: outputScaleToStage * output.size.height,
        scale: outputScaleToStage || 1,
      });
    },
});
