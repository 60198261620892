import { Container as PixiContainer, Stage as PixiStage } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import { useEffect, useRef } from 'react';
// eslint-disable-next-line camelcase
import { useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil';

import '@css/Canvas.css';

import { TimelinePlaybackProvider } from '@context/TimelinePlaybackProvider';

import CanvasTrack from '@feature/studio/canvas/CanvasTrack';

import ErrorBoundary from '@components/error/ErrorBoundary';

import { backgroundState, trackIdsState } from '@store/atoms/EditState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';
import { outputDimensionsSelector } from '@store/studio/Output';

// @ts-ignore
// eslint-disable-next-line
window.__PIXI_INSPECTOR_GLOBAL_HOOK__ && window.__PIXI_INSPECTOR_GLOBAL_HOOK__.register({ PIXI });

PIXI.utils.skipHello();

function CanvasStage({ children }) {
  const stageRef = useRef(null);
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
  const output = useRecoilValue(outputDimensionsSelector);
  const trackIds = useRecoilValue(trackIdsState);
  const backgroundColor = useRecoilValue(backgroundState);
  const [canvas, setCanvas] = useRecoilState(canvasStageState({}));
  const tracks = (trackIds || []).slice().reverse();

  useEffect(() => {
    if (stageRef.current) {
      setCanvas({
        // @ts-ignore
        containerWidth: stageRef?.current?.clientWidth,
        containerHeight: stageRef?.current?.clientHeight,
      });
    }
  }, [stageRef, output?.size, setCanvas]);

  return (
    <div ref={stageRef} className="canvas" data-testid="canvas-stage">
      <div className="canvas__inner">
        {canvas.ready && (
          <ErrorBoundary message="Check your JSON is valid and correct and try again.">
            <PixiStage
              className="shadow"
              width={canvas.stage.width}
              height={canvas.stage.height}
              style={{
                backgroundColor,
              }}
              options={{
                autoDensity: true,
                resolution: window.devicePixelRatio,
                backgroundAlpha: 0,
                antialias: false,
                width: canvas.width,
                height: canvas.height,
              }}
            >
              <PixiContainer scale={canvas.stage.scale} sortableChildren>
                <RecoilBridge>
                  <TimelinePlaybackProvider>
                    {tracks.map((id, index) => (
                      <CanvasTrack key={id} id={id} index={index} />
                    ))}
                  </TimelinePlaybackProvider>
                </RecoilBridge>
              </PixiContainer>
            </PixiStage>
          </ErrorBoundary>
        )}
        {children}
      </div>
    </div>
  );
}

export default CanvasStage;
