import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import '@css/TemplateConfiguration.css';

import { IconAudio, IconChevronDown, IconChevronUp, IconImage, IconVideo } from '@assets/icons';

import OutputPresetButtons from '@feature/studio/configuration/OutputPresetButtons';
import SettingBackground from '@feature/studio/setting/SettingBackground';
import SettingCache from '@feature/studio/setting/SettingCache';
import SettingOutput from '@feature/studio/setting/SettingOutput';
import SettingPosterFields from '@feature/studio/setting/SettingPosterFields';

import InputText from '@components/controls/InputText';

import { nameState } from '@store/atoms/TemplateState';
import { sizeAtomFamily } from '@store/studio/Output';

const PRESET_WIDTH = 1024;
const PRESET_HEIGHT = 576;

const getOutputPresets = (outputWidth = PRESET_WIDTH, outputHeight = PRESET_HEIGHT) => ({
  video: {
    icon: <IconVideo size={44} />,
    title: 'Video',
    format: 'mp4',
    caption: `${outputWidth}px x ${outputHeight}px`,
  },
  image: {
    icon: <IconImage size={44} />,
    title: 'Image',
    format: 'png',
    caption: `${outputWidth}px x ${outputHeight}px`,
  },
  audio: {
    icon: <IconAudio size={44} />,
    title: 'Audio',
    format: 'mp3',
    caption: 'MP3',
  },
});

function TemplateConfiguration({ onHide = () => {} }) {
  const [templateName, setTemplateName] = useRecoilState(nameState);
  const outputWidth = useRecoilValue(sizeAtomFamily('width'));
  const outputHeight = useRecoilValue(sizeAtomFamily('height'));

  const [modalShow, setModalShow] = useState(true);
  const [advancedSettingsShow, setAdvancedSettingsShow] = useState(false);

  const handleHide = () => {
    setModalShow(false);
    onHide();
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleToggleAdvancedSettings = () => {
    setAdvancedSettingsShow(!advancedSettingsShow);
  };

  return (
    <Modal onHide={handleHide} show={modalShow} size="lg" centered className="template-configuration">
      <Modal.Header closeButton>
        <Modal.Title>New Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputText label="Template Name" handleBlur={handleTemplateNameChange} defaultValue={templateName} />
        </div>

        {!advancedSettingsShow && (
          <div>
            <p className="form-label">Output</p>
            <div className="output-types">
              <OutputPresetButtons presets={getOutputPresets(outputWidth, outputHeight)} />
            </div>
          </div>
        )}

        <button type="button" className="unstyled advanced-toggle" onClick={handleToggleAdvancedSettings}>
          <span>
            Advanced Settings {advancedSettingsShow ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
          </span>
        </button>

        {advancedSettingsShow && (
          <div className="settings">
            <SettingOutput />
            <SettingBackground />
            <SettingPosterFields />
            <SettingCache />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleHide}>Done</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TemplateConfiguration;
