export const calculateDimensions = (params) => {
  const { assetHeight, assetWidth, canvasHeight, canvasWidth, dimensions = undefined, fit = 'crop', scale } = params;

  if (!assetWidth || !assetHeight) {
    return { aspectRatio: 1, width: canvasWidth, height: canvasHeight, scale };
  }

  let aspectRatio = Math.max(canvasWidth / assetWidth, canvasHeight / assetHeight);
  let width;
  let height;

  switch (fit) {
    case 'contain':
      aspectRatio = Math.min(canvasWidth / assetWidth, canvasHeight / assetHeight);
      width = aspectRatio * assetWidth * scale;
      height = aspectRatio * assetHeight * scale;
      break;
    case 'cover':
      width = canvasWidth * scale;
      height = canvasHeight * scale;
      break;
    case 'none':
      width = dimensions ? dimensions.width * scale : assetWidth * scale;
      height = dimensions ? dimensions.height * scale : assetHeight * scale;
      break;
    default:
      width = aspectRatio * assetWidth * scale;
      height = aspectRatio * assetHeight * scale;
  }

  return {
    aspectRatio,
    height,
    width,
    scale,
  };
};

export const calculatePosition = (params) => {
  const {
    assetWidth,
    assetHeight,
    canvasHeight,
    canvasWidth,
    position = 'center',
    positionX = undefined,
    positionY = undefined,
    scale,
  } = params;

  let x;
  let y;

  if (!assetWidth || !assetHeight) {
    return { x: canvasWidth / 2, y: canvasHeight / 2 };
  }

  if (typeof positionX !== 'undefined' && typeof positionY !== 'undefined') {
    return {
      x: canvasWidth / 2 + canvasWidth * positionX,
      y: canvasHeight / 2 - canvasHeight * positionY,
    };
  }

  switch (position) {
    case 'top':
      x = canvasWidth / 2;
      y = assetHeight / 2 - (assetHeight - assetHeight * scale) / 2;
      break;
    case 'topRight':
      x = canvasWidth - assetWidth / 2 + (assetWidth - assetWidth * scale) / 2;
      y = assetHeight / 2 - (assetHeight - assetHeight * scale) / 2;
      break;
    case 'right':
      x = canvasWidth - assetWidth / 2 + (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight / 2;
      break;
    case 'bottomRight':
      x = canvasWidth - assetWidth / 2 + (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight - assetHeight / 2 + (assetHeight - assetHeight * scale) / 2;
      break;
    case 'bottom':
      x = canvasWidth / 2;
      y = canvasHeight - assetHeight / 2 + (assetHeight - assetHeight * scale) / 2;
      break;
    case 'bottomLeft':
      x = assetWidth / 2 - (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight - assetHeight / 2 + (assetHeight - assetHeight * scale) / 2;
      break;
    case 'left':
      x = assetWidth / 2 - (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight / 2;
      break;
    case 'topLeft':
      x = assetWidth / 2 - (assetWidth - assetWidth * scale) / 2;
      y = assetHeight / 2 - (assetHeight - assetHeight * scale) / 2;
      break;
    default:
      x = canvasWidth / 2;
      y = canvasHeight / 2;
  }

  return {
    x,
    y,
  };
};

export const calculateResize = (params) => {
  const { assetHeight, assetWidth, position, mousePosition, handlePosition, xResizeOrigin, yResizeOrigin } = params;

  let xMove = 0;
  let yMove = 0;

  const resizedPosition = { ...position };

  switch (handlePosition) {
    case 'top':
      yMove = mousePosition.y - yResizeOrigin;
      resizedPosition.y = position.y + yMove / 2;
      break;
    case 'right':
      xMove = mousePosition.x - xResizeOrigin;
      resizedPosition.x = position.x + xMove / 2;
      break;
    case 'bottom':
      yMove = yResizeOrigin - mousePosition.y;
      resizedPosition.y = position.y - yMove / 2;
      break;
    case 'left':
      xMove = xResizeOrigin - mousePosition.x;
      resizedPosition.x = position.x - xMove / 2;
      break;
    default:
      xMove = mousePosition.x - xResizeOrigin;
      yMove = mousePosition.y - yResizeOrigin;
  }

  return {
    resizedPosition,
    resizedWidth: Math.round(assetWidth + xMove),
    resizedHeight: Math.round(assetHeight - yMove),
  };
};

export const calculateScale = (params) => {
  const {
    assetHeight,
    assetWidth,
    mousePosition,
    position,
    scaleOrigin,
    xScaleOrigin,
    yScaleOrigin,
    fit,
    canvasHeight,
    canvasWidth,
  } = params;

  let xMove;
  let yMove;

  switch (position) {
    case 'topLeft':
      xMove = xScaleOrigin - mousePosition.x;
      yMove = yScaleOrigin - mousePosition.y;
      break;
    case 'bottomLeft':
      xMove = xScaleOrigin - mousePosition.x;
      yMove = mousePosition.y - yScaleOrigin;
      break;
    case 'topRight':
      xMove = mousePosition.x - xScaleOrigin;
      yMove = yScaleOrigin - mousePosition.y;
      break;
    default:
      xMove = mousePosition.x - xScaleOrigin;
      yMove = mousePosition.y - yScaleOrigin;
  }

  // By multiplying the movement by two thirds prior to obtaining the square value,
  // we can roughly simulate the velocity of a change in scale.
  const moveValue = (xMove * 1.66) ** 2 + (yMove * 1.66) ** 2;

  let moveScale = Math.sqrt(moveValue) / assetWidth;

  if (xMove + yMove < 0) {
    moveScale *= -1;
  }

  let scale = scaleOrigin + moveScale;

  if (scale <= 0.01) {
    scale = 0.01;
  }

  let resizedHeight = assetHeight;

  if (fit === 'cover') {
    const aspectRatio = Math.max(canvasWidth / assetWidth, canvasHeight / assetHeight);
    resizedHeight = canvasHeight / aspectRatio;
  }

  return {
    resizedScale: scale,
    resizedWidth: Math.round(assetWidth * scale),
    resizedHeight: Math.round(resizedHeight * scale),
  };
};
