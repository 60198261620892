import { useEffect, useState } from 'react';

import captureFrames from '@utils/editor/captureFrames';

function useCaptureFrames({ asset, fps = 1 }) {
  const hasSrc = !!asset?.src;
  const [frames, setFrames] = useState([]);
  const [loadingFrames, setLoadingFrames] = useState(hasSrc);

  useEffect(() => {
    if (!hasSrc) {
      setFrames([]);
      return;
    }

    setLoadingFrames(true);
    captureFrames(asset.src, fps)
      .then((fetchedFrames) => {
        setFrames(fetchedFrames);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingFrames(false);
      });
  }, [asset.src, fps, hasSrc]);

  return {
    frames,
    loadingFrames,
  };
}

export default useCaptureFrames;
