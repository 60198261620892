import cn from 'classnames';
import { useRecoilValue } from 'recoil';

import '@css/TimelineClip.css';

import TimelineClipAudio from '@feature/studio/timeline/TimelineClipAudio';
import TimelineClipImage from '@feature/studio/timeline/TimelineClipImage';
import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';
import TimelineClipMask from '@feature/studio/timeline/TimelineClipMask';
import TimelineClipMore from '@feature/studio/timeline/TimelineClipMore';
import TimelineClipOverlay from '@feature/studio/timeline/TimelineClipOverlay';
import TimelineClipText from '@feature/studio/timeline/TimelineClipText';
import TimelineClipVideo from '@feature/studio/timeline/TimelineClipVideo';

import { clipErrorsFamily } from '@store/atoms/EditState';

const clipComponentMap = {
  video: TimelineClipVideo,
  audio: TimelineClipAudio,
  image: TimelineClipImage,
  text: TimelineClipText,
  title: TimelineClipText,
  overlay: TimelineClipOverlay,
  mask: TimelineClipMask,
};

function TimelineClip({
  id,
  x,
  y,
  clip,
  clipType,
  asset,
  handleDeleteClip,
  activeClip,
  width,
  maxWidth,
  trackId,
  isVisible,
  getRef,
}) {
  const clipErrors = useRecoilValue(clipErrorsFamily(id));

  const clipStyles = {
    transform: `translate(${x}px, ${y}px)`,
    width: `${width}px`,
  };
  const TimelineClipComponent = clipComponentMap[clipType];
  if (!TimelineClipComponent) {
    return null;
  }

  return (
    <div
      ref={getRef}
      className={cn('clip', {
        [clipType]: true,
        active: activeClip,
        'clip--hidden-on-canvas': !isVisible,
        error: Array.isArray(clipErrors) && clipErrors.length,
      })}
      style={clipStyles}
      data-clip-id={id}
      data-track-id={trackId}
    >
      {/* <TimelineClipMore handleDeleteClip={handleDeleteClip} /> */}
      <TimelineClipComponent clipType={clipType} asset={asset} maxWidth={maxWidth} />
      <TimelineClipInfo start={clip.start} length={clip.length} />
    </div>
  );
}

export default TimelineClip;
