/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; alpha: number; }>}
 */
export const slideLeftTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = canvas.width * (clip.position.x / canvas.width + slidePercent);
  const slideTo = clip.position.x;

  return {
    from: {
      x: slideFrom,
      alpha: 0,
    },
    to: {
      x: slideTo,
      alpha: 1,
    },
    start,
    end,
    interpolation: 'easeIn',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; alpha: number; }>}
 */
export const slideLeftTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.x;
  const slideTo = canvas.width * (clip.position.x / canvas.width - slidePercent);

  return {
    from: {
      x: slideFrom,
      alpha: 1,
    },
    to: {
      x: slideTo,
      alpha: 0,
    },
    start,
    end,
    interpolation: 'easeOut',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; alpha: number; }>}
 */
export const slideRightTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = canvas.width * (clip.position.x / canvas.width - slidePercent);
  const slideTo = clip.position.x;

  return {
    from: {
      x: slideFrom,
      alpha: 0,
    },
    to: {
      x: slideTo,
      alpha: 1,
    },
    start,
    end,
    interpolation: 'easeIn',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; alpha: number; }>}
 */
export const slideRightTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.x;
  const slideTo = canvas.width * (clip.position.x / canvas.width + slidePercent);

  return {
    from: {
      x: slideFrom,
      alpha: 1,
    },
    to: {
      x: slideTo,
      alpha: 0,
    },
    start,
    end,
    interpolation: 'easeOut',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; alpha: number; }>}
 */
export const slideUpTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = canvas.height * (clip.position.y / canvas.height + slidePercent);
  const slideTo = clip.position.y;

  return {
    from: {
      y: slideFrom,
      alpha: 0,
    },
    to: {
      y: slideTo,
      alpha: 1,
    },
    start,
    end,
    interpolation: 'easeIn',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; alpha: number; }>}
 */
export const slideUpTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.y;
  const slideTo = canvas.height * (clip.position.y / canvas.height - slidePercent);

  return {
    from: {
      y: slideFrom,
      alpha: 1,
    },
    to: {
      y: slideTo,
      alpha: 0,
    },
    start,
    end,
    interpolation: 'easeIn',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; alpha: number; }>}
 */
export const slideDownTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = canvas.height * (clip.position.y / canvas.height - slidePercent);
  const slideTo = clip.position.y;

  return {
    from: {
      y: slideFrom,
      alpha: 0,
    },
    to: {
      y: slideTo,
      alpha: 1,
    },
    start,
    end,
    interpolation: 'easeOut',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; alpha: number; }>}
 */
export const slideDownTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.y;
  const slideTo = canvas.height * (clip.position.y / canvas.height + slidePercent);

  return {
    from: {
      y: slideFrom,
      alpha: 1,
    },
    to: {
      y: slideTo,
      alpha: 0,
    },
    start,
    end,
    interpolation: 'easeIn',
  };
};
