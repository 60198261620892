import { useState } from 'react';

import CanvasFrameHandle from '@feature/studio/canvas/CanvasFrameHandle';
import CanvasFrameOutline from '@feature/studio/canvas/CanvasFrameOutline';

function CanvasEditFrame(props) {
  const {
    bounds,
    width,
    height,
    handlePositionDrag,
    handlePositionDrop,
    handlePositionGrab,
    handleResizeDrag = undefined,
    handleResizeDrop = undefined,
    handleResizeGrab = undefined,
    handleScaleDrag,
    handleScaleDrop,
    handleScaleGrab,
    inView,
    selected,
    zIndex,
  } = props;

  const { topLeft, top, topRight, right, bottomLeft, bottom, bottomRight, left } = bounds;
  const [visible, setVisible] = useState(false);
  const [isAdjusting, setIsAdjusting] = useState(false);

  return (
    <>
      <CanvasFrameOutline
        bounds={bounds}
        width={width}
        handleGrab={handlePositionGrab}
        handleDrag={handlePositionDrag}
        handleDrop={handlePositionDrop}
        height={height}
        inView={inView}
        visible={visible || selected}
        setVisible={setVisible}
        isAdjusting={isAdjusting}
        zIndex={zIndex}
      />
      {handleScaleGrab ? (
        <>
          <CanvasFrameHandle
            coordinates={topLeft}
            handleDrag={handleScaleDrag}
            handleDrop={handleScaleDrop}
            handleGrab={handleScaleGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="topLeft"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          <CanvasFrameHandle
            coordinates={topRight}
            handleDrag={handleScaleDrag}
            handleDrop={handleScaleDrop}
            handleGrab={handleScaleGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="topRight"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          <CanvasFrameHandle
            coordinates={bottomRight}
            handleDrag={handleScaleDrag}
            handleDrop={handleScaleDrop}
            handleGrab={handleScaleGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="bottomRight"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          <CanvasFrameHandle
            coordinates={bottomLeft}
            handleDrag={handleScaleDrag}
            handleDrop={handleScaleDrop}
            handleGrab={handleScaleGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="bottomLeft"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
        </>
      ) : null}
      {handleResizeGrab ? (
        <>
          <CanvasFrameHandle
            coordinates={top}
            handleDrag={handleResizeDrag}
            handleDrop={handleResizeDrop}
            handleGrab={handleResizeGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="top"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          <CanvasFrameHandle
            coordinates={right}
            handleDrag={handleResizeDrag}
            handleDrop={handleResizeDrop}
            handleGrab={handleResizeGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="right"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          <CanvasFrameHandle
            coordinates={bottom}
            handleDrag={handleResizeDrag}
            handleDrop={handleResizeDrop}
            handleGrab={handleResizeGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="bottom"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          <CanvasFrameHandle
            coordinates={left}
            handleDrag={handleResizeDrag}
            handleDrop={handleResizeDrop}
            handleGrab={handleResizeGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="left"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
        </>
      ) : null}
    </>
  );
}

export default CanvasEditFrame;
