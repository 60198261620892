import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';

function Error() {
  return (
    <Layout>
      <Header title="Error">
        <p>
          An error occurred. Try refreshing the page and returning to the dashboard <a href="/">home page</a>. If the
          problem persists please contact us at{' '}
          <a href="mailto:support@shotstack.io?subject=Dashboard support">support@shotstack.io</a>
        </p>
      </Header>
    </Layout>
  );
}

export default Error;
