import ErrorToast from '@components/error/ErrorToast';
import config from '@config';
import { Auth0Provider } from '@context/Auth0ReactSPA';
import '@css/index.css';
import Routes from '@src/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { RecoilEnv, RecoilRoot } from 'recoil';
import { RecoilDevTools } from 'recoil-gear';
import RecoilNexus from 'recoil-nexus';

const { isDev } = config;

if (isDev) {
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
}

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        {config.env !== 'production' && <RecoilDevTools />}
        <RecoilNexus />
          <ErrorToast />
          <Routes />
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
