export const INTEGRATION_LIMITS = {
  free: 1,
  basic: 3,
  pro: Infinity,
  enterprise: Infinity,
};

export const WORKFLOW_LIMITS = {
  workflows: {
    free: 1,
    basic: Infinity,
    pro: Infinity,
    enterprise: Infinity,
  },
  actions: {
    free: 15,
    basic: 15,
    pro: 50,
    enterprise: 50,
  },
};

export const STUDIO_LIMITS = {
  fps: {
    free: 30,
    basic: Infinity,
    pro: Infinity,
    enterprise: Infinity,
  },
};

export const PLAN_NAMES = {
  free: 'Free',
  basic: 'Essentials',
  pro: 'Professional',
  enterprise: 'Enterprise',
};

export const getNextLimit = (limits, currentPlan) => {
  const planKeys = Object.keys(limits);
  const nextPlanKey = planKeys[planKeys.indexOf(currentPlan) + 1];
  return nextPlanKey ? { name: PLAN_NAMES[nextPlanKey], limit: limits[nextPlanKey] } : null;
};
