import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import InputSelect from '@components/controls/InputSelect';

import { fpsAtom, frameRatesSelector } from '@store/studio/Output';

const DEFAULT_FPS = 25;

function FrameRateSelect({ tracking }) {
  const [fps, setFps] = useRecoilState(fpsAtom);
  const { state, contents } = useRecoilValueLoadable(frameRatesSelector);
  const frameRates = state === 'hasValue' ? contents : [];

  const handleChange = (event) => {
    const { value } = event.target;
    setFps(value.length > 0 ? parseFloat(value) : DEFAULT_FPS);
  };

  return (
    <InputSelect
      handleChange={handleChange}
      label="Frame Rate"
      selected={fps || DEFAULT_FPS}
      tracking={{
        ...tracking,
        name: 'Select Output Frame Rate',
      }}
      values={frameRates}
    />
  );
}

export default FrameRateSelect;
