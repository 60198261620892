import { atom, atomFamily } from 'recoil';

export const activeClipState = atom({
  key: 'activeClipState',
  default: null,
});

export const clipTypesFamily = atomFamily({
  key: 'clip/types',
  default: null,
});
