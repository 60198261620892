import { atom } from 'recoil';

export const templateLoadingState = atom({
  key: 'template/loading',
  default: false,
});

export const nameState = atom({
  key: 'template/name',
  default: 'Untitled',
});

export const idState = atom({
  key: 'template/id',
  default: null,
});

export const bulkTemplateDeleteState = atom({
  key: 'bulkTemplateDeleteState',
  default: [],
});
