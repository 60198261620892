import { jsonParse } from '@utils/jsonParse';

export const transformResponseAxiosDefault = [
  (response) => {
    if (typeof response === 'string' && response.length === 0) {
      return {};
    }
    const responseJson = jsonParse(response);
    const { data, response: responseData } = responseJson;
    if (!data) {
      return { ...(responseData ?? responseJson) };
    }
    return { ...data };
  },
];
