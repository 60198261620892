import getResolution from '@utils/getResolution';

export const setHTMLAssetDimensions = (asset, output) => {
  if (asset.type !== 'html' || (asset.width && asset.height)) {
    return asset;
  }

  const { size, resolution } = output || {};
  const [resWidth, resHeight] = getResolution(resolution);

  return {
    ...asset,
    width: size?.width || resWidth,
    height: size?.height || resHeight,
  };
};
