import { useRecoilState } from 'recoil';

import InputNumber from '@components/controls/InputNumber';

import { posterAtomFamily } from '@store/studio/Output';

import { parseNumberOrEmptyString } from '@utils/numbers';

function PosterCaptureInput({ tracking }) {
  const [capture, setCapture] = useRecoilState(posterAtomFamily('capture'));

  const handleChange = (event) => {
    const { value } = event.target;
    setCapture(parseNumberOrEmptyString(value));
  };

  return (
    <InputNumber
      label="Capture"
      value={capture ?? ''}
      min={0}
      step={0.01}
      tracking={{
        ...tracking,
        name: 'Change Poster Image Capture',
      }}
      handleChange={handleChange}
    />
  );
}

export default PosterCaptureInput;
