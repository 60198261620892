const MAX_FRAME_INTERVAL = 20;
const MAX_FRAME_WIDTH = 200;

const createVideoElement = (src, type = 'video/mp4') => {
  const video = document.createElement('video');
  video.crossOrigin = 'anonymous';
  video.classList.add('video-capture');

  const source = document.createElement('source');
  source.src = src;
  source.type = type;

  video.appendChild(source);
  document.getElementById('root').appendChild(video);

  return video;
};

const captureFrames = (src, frameRate) => {
  if (src === undefined) {
    return Promise.resolve([]);
  }
  const videoElement = createVideoElement(src);

  return new Promise((resolve, reject) => {
    videoElement.addEventListener(
      'error',
      (err) => {
        reject(err);
      },
      true
    );

    videoElement.addEventListener('loadedmetadata', () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const frames = [];

      // Calculate the frame interval based on the desired frame rate
      const frameInterval = videoElement.duration / Math.min(videoElement.duration * frameRate, MAX_FRAME_INTERVAL);

      // Determine the new width and height, keeping aspect ratio
      const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      const newWidth = Math.min(videoElement.videoWidth, MAX_FRAME_WIDTH); // limit width to 200px
      const newHeight = newWidth / aspectRatio;

      // Resize the canvas
      canvas.width = newWidth;
      canvas.height = newHeight;

      function capture() {
        try {
          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          frames.push(canvas.toDataURL('image/png'));
        } catch (e) {
          console.error('Frame capture failed: ', e);
          reject(e);
        }

        if (frames.length < Math.min(videoElement.duration * frameRate, MAX_FRAME_INTERVAL)) {
          // Seek to the next frame time
          videoElement.currentTime += frameInterval;
        } else {
          // We are done, remove event listener
          videoElement.removeEventListener('seeked', capture);
          videoElement.remove();
          resolve(frames);
        }
      }

      // Add event listener and start capturing frames
      videoElement.addEventListener('seeked', capture);
      videoElement.currentTime = 0;
    });
  });
};

export default captureFrames;
