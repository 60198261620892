import '@fontsource/kanit/700.css';
import '@fontsource/nunito-sans';
import '@fontsource/nunito-sans/700.css';
import '@fontsource/nunito-sans/800.css';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from '@src/serviceWorker';

import App from '@components/app/App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);

serviceWorker.unregister();
