import { useRecoilState } from 'recoil';

import InputNumber from '@components/controls/InputNumber';

import { sizeAtomFamily } from '@store/studio/Output';

import { parseNumberOrEmptyString } from '@utils/numbers';

function WidthInput() {
  const [width, setWidth] = useRecoilState(sizeAtomFamily('width'));

  const handleChange = (event) => {
    const { value } = event.target;
    setWidth(parseNumberOrEmptyString(value, false));
  };

  return <InputNumber label="Width" value={width} min={0} handleChange={handleChange} />;
}

export default WidthInput;
