import cn from 'classnames';
import { Container } from 'react-bootstrap';

function Layout(props) {
  const { children, fluid, className } = props;
  return (
    <Container className={cn('h-100', { [className]: className })} fluid={fluid}>
      {children}
    </Container>
  );
}

export default Layout;
