/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number }>}
 */
export const zoomInMotionKeyframe = (start, end, properties) => {
  const { speed, clip } = properties;

  const scaleFrom = 1;
  let scaleTo = 1.3;
  if (speed === 'slow') scaleTo = 1.1;
  if (speed === 'fast') scaleTo = 1.7;

  return {
    from: {
      scale: clip.type === 'html' ? scaleFrom : scaleFrom * clip.scale,
    },
    to: {
      scale: clip.type === 'html' ? scaleTo : scaleTo * clip.scale,
    },
    start,
    end,
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number }>}
 */
export const zoomOutMotionKeyframe = (start, end, properties) => {
  const { speed, clip } = properties;

  let scaleFrom = 1.3;
  if (speed === 'slow') scaleFrom = 1.1;
  if (speed === 'fast') scaleFrom = 1.7;
  const scaleTo = 1;

  return {
    from: {
      scale: clip.type === 'html' ? scaleFrom : scaleFrom * clip.scale,
    },
    to: {
      scale: clip.type === 'html' ? scaleTo : scaleTo * clip.scale,
    },
    start,
    end,
  };
};
