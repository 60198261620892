import { atom, atomFamily, selector, selectorFamily, useRecoilCallback } from 'recoil';

import { authPlan } from '@store/atoms/AuthState';

import FrameRates from '@constants/FrameRates';
import OutputPresets from '@constants/OutputPresets';
import { STUDIO_LIMITS } from '@constants/PlanLimits';

import formatOutputDimensions from '@utils/editor/formatOutputDimensions';
import getResolution from '@utils/getResolution';
import { removeEmptyEntries, removeMetaData } from '@utils/template';

const defaultFormat = 'mp4';
const defaultResolution = '';
const defaultAspectRatio = '';
const defaultFps = 25;
const defaultScaleTo = '';
const defaultQuality = '';
const defaultRepeat = '';
const defaultMute = '';
const defaultDestinationIds = [];
const defaultSize = {
  width: 1024,
  height: 576,
};
const defaultRange = {
  start: '',
  length: '',
};
const defaultThumbnail = {
  capture: '',
  scale: '',
};

export const formatAtom = atom({
  key: 'output/format',
  default: defaultFormat,
});

export const resolutionAtom = atom({
  key: 'output/resolution',
  default: defaultResolution,
});

export const aspectRatioAtom = atom({
  key: 'output/aspectRatio',
  default: defaultAspectRatio,
});

export const fpsAtom = atom({
  key: 'output/fps',
  default: defaultFps,
});

export const scaleToAtom = atom({
  key: 'output/scaleTo',
  default: defaultScaleTo,
});

export const qualityAtom = atom({
  key: 'output/quality',
  default: defaultQuality,
});

export const repeatAtom = atom({
  key: 'output/repeat',
  default: defaultRepeat,
});

export const muteAtom = atom({
  key: 'output/mute',
  default: defaultMute,
});

export const destinationIdsAtom = atom({
  key: 'output/destination/ids',
  default: defaultDestinationIds,
});

export const sizeAtomFamily = atomFamily({
  key: 'output/sizeFamily',
  default: (type) => defaultSize[type],
});

export const rangeAtomFamily = atomFamily({
  key: 'output/rangeFamily',
  default: (type) => defaultRange[type],
});

const defaultPoster = {
  capture: '',
};

export const posterAtomFamily = atomFamily({
  key: 'output/posterFamily',
  default: (type) => defaultPoster[type],
});

export const thumbnailAtomFamily = atomFamily({
  key: 'output/thumbnailFamily',
  default: (type) => defaultThumbnail[type],
});

export const destinationAtomFamily = atomFamily({
  key: 'output/destinationFamily',
  default: [],
});

export const outputPresetSelector = selector({
  key: 'output/preset',
  get: ({ get }) => {
    const width = get(sizeAtomFamily('width'));
    const height = get(sizeAtomFamily('height'));

    let selectedPreset = OutputPresets.find((presetItem) => {
      return presetItem?.width === width && presetItem?.height === height;
    });

    if (!selectedPreset) {
      [selectedPreset] = OutputPresets;
    }

    return selectedPreset;
  },
  set: ({ set }, newPreset) => {
    if (newPreset === 'custom') {
      const defaultPreset = OutputPresets[0];
      set(sizeAtomFamily('width'), defaultPreset.width);
      set(sizeAtomFamily('height'), defaultPreset.height);
      return;
    }

    const selectedPreset = OutputPresets.find(({ value }) => value === newPreset);
    if (!selectedPreset) return;

    set(resolutionAtom, undefined);
    set(sizeAtomFamily('width'), selectedPreset.width);
    set(sizeAtomFamily('height'), selectedPreset.height);
  },
});

export const frameRatesSelector = selector({
  key: 'output/frameRates',
  get: async ({ get }) => {
    const plan = await get(authPlan);
    const { fps: fpsLimits } = STUDIO_LIMITS;
    const planLimit = fpsLimits[plan];

    const frameRates = FrameRates.map((fps) => ({
      ...fps,
      disabled: fps.value > planLimit,
    }));

    return frameRates;
  },
});

export const destinationsSelector = selector({
  key: 'output/destinations',
  get: ({ get }) => {
    const destinationIds = get(destinationIdsAtom);
    const destinations = destinationIds.reduce((acc, destinationId) => {
      let destination = get(destinationAtomFamily(destinationId));
      const isEnabled = destination?.meta?.enabled;
      const hasOptions = Object.keys(destination?.options || {}).length > 0;
      if (!isEnabled || !hasOptions) {
        return acc;
      }
      // unpack options for shotstack provider
      if (destination.provider === 'shotstack') {
        const { options, ...rest } = destination;
        destination = {
          ...rest,
          ...options,
        };
      }

      acc.push(destination);
      return acc;
    }, []);
    return destinations;
  },
});

export const outputSelectorFamily = selectorFamily({
  key: 'output',
  get:
    (debug = false) =>
    ({ get }) => {
      const output = {
        format: get(formatAtom),
        resolution: get(resolutionAtom),
        aspectRatio: get(aspectRatioAtom),
        fps: get(fpsAtom),
        scaleTo: get(scaleToAtom),
        quality: get(qualityAtom),
        repeat: get(repeatAtom),
        mute: get(muteAtom),
        size: {
          width: get(sizeAtomFamily('width')),
          height: get(sizeAtomFamily('height')),
        },
        range: {
          start: get(rangeAtomFamily('start')),
          length: get(rangeAtomFamily('length')),
        },
        poster: {
          capture: get(posterAtomFamily('capture')),
        },
        thumbnail: {
          capture: get(thumbnailAtomFamily('capture')),
          scale: get(thumbnailAtomFamily('scale')),
        },
        destinations: get(destinationsSelector),
      };
      if (!debug) {
        const sanitizedOutput = removeEmptyEntries(output);
        sanitizedOutput.destinations = removeMetaData(output.destinations);
        return sanitizedOutput;
      }
      return output;
    },
});

export const outputDimensionsSelector = selector({
  key: 'output/dimensions',
  get: ({ get }) => {
    const output = get(outputSelectorFamily(false));
    return formatOutputDimensions(output);
  },
});

export const resetOutputCallback = (callbackArgs) => {
  const { reset, snapshot } = callbackArgs;
  return () => {
    reset(formatAtom);
    reset(resolutionAtom);
    reset(aspectRatioAtom);
    reset(fpsAtom);
    reset(scaleToAtom);
    reset(qualityAtom);
    reset(repeatAtom);
    reset(muteAtom);

    reset(sizeAtomFamily('width'));
    reset(sizeAtomFamily('height'));

    reset(rangeAtomFamily('start'));
    reset(rangeAtomFamily('length'));

    reset(posterAtomFamily('capture'));

    reset(thumbnailAtomFamily('capture'));
    reset(thumbnailAtomFamily('scale'));

    const currentDestinationsIdsAtom = snapshot.getLoadable(destinationIdsAtom).contents;
    currentDestinationsIdsAtom.forEach((destinationId) => reset(destinationAtomFamily(destinationId)));
  };
};

export const hydrateOutputCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return (output) => {
    set(formatAtom, output?.format || defaultFormat);
    set(aspectRatioAtom, output?.aspectRatio || defaultAspectRatio);
    set(fpsAtom, output?.fps || defaultFps);
    set(scaleToAtom, output?.scaleTo || defaultScaleTo);
    set(qualityAtom, output?.quality || defaultQuality);
    set(repeatAtom, output?.repeat || defaultRepeat);
    set(muteAtom, output?.mute || defaultMute);

    let size = output?.size || {};
    if (output?.resolution) {
      set(resolutionAtom, undefined);
      const [width, height] = getResolution(output.resolution);
      size = { width, height };
    } else {
      set(resolutionAtom, output?.resolution || defaultResolution);
    }

    Object.keys(defaultSize).forEach((key) => {
      set(sizeAtomFamily(key), size?.[key] || defaultSize[key]);
    });

    Object.keys(defaultRange).forEach((key) => {
      set(rangeAtomFamily(key), output?.range?.[key] ?? defaultRange[key]);
    });

    Object.keys(defaultPoster).forEach((key) => {
      set(posterAtomFamily(key), output?.poster?.[key] ?? defaultPoster[key]);
    });

    Object.keys(defaultThumbnail).forEach((key) => {
      set(thumbnailAtomFamily(key), output?.thumbnail?.[key] ?? defaultThumbnail[key]);
    });

    const integrationProviders = ['shotstack', 'mux', 's3', 'google-cloud-storage'];
    set(destinationIdsAtom, integrationProviders);

    integrationProviders.forEach((providerId) => {
      const destination = (output?.destinations || []).find(({ provider }) => provider === providerId);
      const { provider, ...rest } = destination || {};

      // TODO: move this to output transform api util
      // creating an options object for each destination (shotstack doesn't have one)
      const newDestination = {
        provider: providerId,
        options: rest.options ?? { ...rest },
        meta: {
          enabled: Boolean(destination),
        },
      };

      set(destinationAtomFamily(providerId), newDestination);
    });
  };
};

export const useResetOutputCallback = () => useRecoilCallback(resetOutputCallback);
export const useHydrateOutputCallback = () => useRecoilCallback(hydrateOutputCallback);
