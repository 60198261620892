/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; left: number, right: number })}
 */
const calculateTextSlideHorizontal = (properties) => {
  const { canvas, clip, speed } = properties;

  let slideTo = canvas.width * 0.08;
  if (speed === 'slow') slideTo = canvas.width * 0.03;
  if (speed === 'fast') slideTo = canvas.width * 0.12;

  return {
    scale: 1,
    left: -(clip.width / 2 + slideTo),
    right: clip.width / 2 + canvas.width + slideTo,
  };
};

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; top: number, bottom: number })}
 */
const calculateTextSlideVertical = (properties) => {
  const { canvas, clip, speed } = properties;

  let slideTo = canvas.height * 0.08;
  if (speed === 'slow') slideTo = canvas.height * 0.03;
  if (speed === 'fast') slideTo = canvas.height * 0.12;

  return {
    scale: 1,
    top: -(clip.height / 2 + slideTo),
    bottom: clip.height / 2 + canvas.height + slideTo,
  };
};

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; left: number, right: number })}
 */
const calculateAssetSlideHorizontal = (properties) => {
  const { canvas, clip, speed } = properties;

  let slideTo = canvas.width * 0.08;
  if (speed === 'slow') slideTo = canvas.width * 0.03;
  if (speed === 'fast') slideTo = canvas.width * 0.12;

  const clipTargetWidth = canvas.width + slideTo * 2;
  const clipFitWidth = (clip.width / clip.height) * canvas.height;

  const shouldScaleUp = clipFitWidth < clipTargetWidth;

  const scale = shouldScaleUp ? clipTargetWidth / clip.width : clip.scale;

  return {
    scale,
    left: -slideTo + canvas.width / 2,
    right: slideTo + canvas.width / 2,
  };
};

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; top: number, bottom: number })}
 */
const calculateAssetSlideVertical = (properties) => {
  const { canvas, clip, speed } = properties;

  let slideTo = canvas.height * 0.08;
  if (speed === 'slow') slideTo = canvas.height * 0.03;
  if (speed === 'fast') slideTo = canvas.height * 0.12;

  const clipTargetHeight = canvas.height + slideTo * 2;
  const clipFitHeight = (clip.height / clip.width) * canvas.width;

  const shouldScaleUp = clipFitHeight < clipTargetHeight;
  const scale = shouldScaleUp ? clipTargetHeight / clip.height : clip.scale;

  return {
    scale,
    top: slideTo + canvas.height / 2,
    bottom: -slideTo + canvas.height / 2,
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; scale: number; }>}
 */
export const slideLeftMotionKeyframe = (start, end, properties) => {
  const { scale, left, right } =
    properties.clip.type === 'html'
      ? calculateTextSlideHorizontal(properties)
      : calculateAssetSlideHorizontal(properties);

  return {
    from: {
      scale,
      x: right,
    },
    to: {
      scale,
      x: left,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; scale: number }>}
 */
export const slideRightMotionKeyframe = (start, end, properties) => {
  const { scale, left, right } =
    properties.clip.type === 'html'
      ? calculateTextSlideHorizontal(properties)
      : calculateAssetSlideHorizontal(properties);

  return {
    from: {
      scale,
      x: left,
    },
    to: {
      scale,
      x: right,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; scale: number; }>}
 */
export const slideUpMotionKeyframe = (start, end, properties) => {
  const { scale, top, bottom } =
    properties.clip.type === 'html' ? calculateTextSlideVertical(properties) : calculateAssetSlideVertical(properties);

  return {
    from: {
      scale,
      y: top,
    },
    to: {
      scale,
      y: bottom,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; scale: number; }>}
 */
export const slideDownMotionKeyframe = (start, end, properties) => {
  const { scale, top, bottom } =
    properties.clip.type === 'html' ? calculateTextSlideVertical(properties) : calculateAssetSlideVertical(properties);

  return {
    from: {
      scale,
      y: bottom,
    },
    to: {
      scale,
      y: top,
    },
    start,
    end,
    interpolation: 'linear',
  };
};
