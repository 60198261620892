import config from '@config';
import { getRecoil } from 'recoil-nexus';

import { authToken } from '@store/atoms/AuthState';
import stageState from '@store/atoms/StageState';

export const getHeaders = ({ withStage, withUserAgent }) => {
  const token = getRecoil(authToken);
  const stage = getRecoil(stageState);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (withStage) {
    headers.stage = stage;
  }

  if (withUserAgent) {
    headers['User-Agent'] = config.headers.userAgent;
  }

  return headers;
};

export const getKsuidFromAssetSrc = (src) => {
  const parts = src.replace('https://', '').replace('/source.mp4', '').split('/');
  return parts[parts.length - 1];
};

export const isIngestSrc = (src) => {
  return /shotstack-ingest-api-stage-sources/.test(src);
};

export const getAsseyKsuid = (asset) => {
  if (!asset || !asset.src || !isIngestSrc(asset.src)) {
    return null;
  }

  return getKsuidFromAssetSrc(asset.src);
};
