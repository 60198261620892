import CanvasStage from '@feature/studio/canvas/CanvasStage';
import TimelineControls from '@feature/studio/timeline/TimelineControls';
import { memo } from 'react';

function WorkspaceCanvas() {
  return (
    <div className='workspace__canvas'>
      <CanvasStage>
        <div className='workspace__controls'>
          <TimelineControls />
        </div>
      </CanvasStage>
    </div>
  );
}

export default memo(WorkspaceCanvas);
