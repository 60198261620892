import { kebabCase } from 'lodash';

import { COLOR, FONT_FAMILY, FONT_SIZE, TEXT, TEXT_ALIGN, TEXT_SCALE } from '@constants/TextAssetDefaults';

import { hasMergeField } from '@utils/merge';
import camelCase from '@utils/text/camelCase';

const defaultCSSValues = {
  color: COLOR,
  fontSize: FONT_SIZE,
  fontFamily: FONT_FAMILY,
  textAlign: TEXT_ALIGN,
  textScale: TEXT_SCALE,
};

export const convertCSSToJSON = (css) => {
  // Remove any prefix before and including "{", and the closing "}" brace
  const cssProperties = css.replace(/^[^{]*{|}$/g, '').trim();

  // Split the string into individual property declarations
  const declarations = cssProperties
    .split(';')
    .map((declaration) => declaration.trim())
    .filter(Boolean);

  // Initialize an empty object to store the properties
  const cssJson = {};

  // Iterate over each declaration and convert it to camelCase JSON format
  declarations.forEach((declaration) => {
    // Split the declaration into property and value
    const [property, value] = declaration.split(':').map((str) => str.trim());
    // strip quotes from value
    const strippedValue = value.replace(/^"|"$/g, '');
    // Convert the property to camelCase
    const camelCaseProperty = camelCase(property);
    // Add the property and value to the cssJson
    cssJson[camelCaseProperty] = strippedValue;
  });
  return cssJson || {};
};

export const convertHTMLToJSON = (html) => {
  // Create a new DOMParser
  const parser = new DOMParser();
  // Use the DOMParser to parse the HTML string
  const doc = parser.parseFromString(html, 'text/html');
  // Get the text content of the parsed HTML
  const { textContent } = doc.body;
  return { text: textContent || '' };
};

const transformCSSPropertyMap = {
  fontSize: (value = FONT_SIZE) => {
    return `${value}px`;
  },
  fontFamily: (value = FONT_FAMILY) => {
    if (hasMergeField(value)) return value;
    return `'${value}'`;
  },
  default: (value, key) => value || defaultCSSValues[key],
};

export const convertJSONToHTML = (props, htmlType = 'text', tag = 'p') => {
  const { text = TEXT, ...css } = props || {};

  const cssPropertiesString = Object.entries(css || {})
    .reduce((acc, [key, value]) => {
      if (value === undefined) return acc;
      const transformCSSProperty = transformCSSPropertyMap[key] || transformCSSPropertyMap.default;
      acc.push([kebabCase(key), transformCSSProperty(value, key)]);
      return acc;
    }, [])
    .map(([key, value]) => `${key}: ${value};`)
    .join(' ');

  return {
    html: `<${tag} data-html-type="${htmlType}">${text}</${tag}>`,
    css: `${tag} { ${cssPropertiesString} }`,
  };
};
