import { useRecoilState } from 'recoil';

import InputSelect from '@components/controls/InputSelect';

import { qualityAtom } from '@store/studio/Output';

import Compression from '@constants/Compression';

const DEFAULT_QUALITY = 'medium';

function QualitySelect({ tracking }) {
  const [quality, setQuality] = useRecoilState(qualityAtom);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuality(value.length > 0 ? value : DEFAULT_QUALITY);
  };

  return (
    <InputSelect
      handleChange={handleChange}
      label="Compression"
      selected={quality || DEFAULT_QUALITY}
      tracking={{
        ...tracking,
        name: 'Select Output Compression',
      }}
      values={Compression}
    />
  );
}

export default QualitySelect;
