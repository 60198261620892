import { memo } from 'react';

import { IconFonts } from '@assets/icons';

function TimelineClipText({ clipType, asset }) {
  const text = [asset?.text, asset?.html, asset?.title].filter(Boolean).join(' ');
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconFonts size={12} />
        </span>
        {text ? (
          <div className="clip__content-raw" dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <span className="clip__type">{clipType}</span>
        )}
      </div>
    </div>
  );
}

export default memo(TimelineClipText);
