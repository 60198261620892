import CanvasMask from './CanvasMask';
import { createClipAnimation } from '@animation/animate';
import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';
import CanvasEditFrame from '@feature/studio/canvas/CanvasEditFrame';
import useMovableAsset from '@hooks/useMovableAsset';
import { Sprite } from '@inlet/react-pixi';
import { clipsFamily } from '@store/atoms/EditState';
import { interactiveState } from '@store/atoms/InteractionState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';
import { activeClipAssetState, clipAssetState, clipState, clipVisibilityState } from '@store/selectors/EditSelectors';
import { useRef, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

function CanvasImagePlayer({ image, id, index }) {
  const spriteRef = useRef();
  const canvas = useRecoilValue(canvasStageState({}));
  const [asset, setAsset] = useRecoilState(clipAssetState(id));
  const clip = useRecoilValue(clipState(id));
  const { clipId: activeClipId } = useRecoilValue(activeClipAssetState);
  const setClip = useSetRecoilState(clipsFamily(id));
  const visible = useRecoilValue(clipVisibilityState(id));
  const { playhead } = useTimelinePlaybackContext();
  const isInteractive = useRecoilValue(interactiveState);

  // @ts-ignore
  const { opacity = 1 } = clip;
  const assetWidth = asset?.meta?.width || image?.source?.width;
  const assetHeight = asset?.meta?.height || image?.source?.height;
  const isSelected = activeClipId === id;

  // prettier-ignore
  // eslint-disable-next-line max-len
  const movableAssetConfig = { id, assetWidth, assetHeight, canvasWidth: canvas.width, canvasHeight: canvas.height, clip, setClip, asset, setAsset, spriteRef };
  const movableAsset = useMovableAsset(movableAssetConfig);
  // prettier-ignore
  // eslint-disable-next-line max-len
  const { bounds, dimensions, handlePositionGrab, handlePositionDrop, handlePositionDrag, handleScaleDrag, handleScaleDrop, handleScaleGrab, isMoving, position, scale } = movableAsset;
  // @ts-ignore
  const clipAnimation = createClipAnimation({ type: 'image', clip, asset, playhead, movableAsset });

  if (!image) {
    return null;
  }

  const contents = (
    <>
      <Sprite
        alpha={opacity}
        anchor={0.5}
        image={image.source}
        height={dimensions.height}
        ref={spriteRef}
        visible={visible}
        width={dimensions.width}
        zIndex={index}
        x={position.x}
        y={position.y}
        {...clipAnimation}
      />
      {isInteractive && (  
        <CanvasEditFrame
          bounds={bounds}
          width={dimensions.width}
          height={dimensions.height}
          isDragging={isMoving}
          handlePositionGrab={handlePositionGrab}
          handlePositionDrag={handlePositionDrag}
          handlePositionDrop={handlePositionDrop}
          handleScaleDrag={handleScaleDrag}
          handleScaleDrop={handleScaleDrop}
          handleScaleGrab={handleScaleGrab}
          inView={visible}
          selected={isSelected}
          scale={scale}
          zIndex={index}
        />
      )}
    </>
  );

  return (
    <CanvasMask x={position.x} y={position.y} width={dimensions.width + 1} height={dimensions.height + 1} clipId={id}>
      {contents}
    </CanvasMask>
  );
}

export default CanvasImagePlayer;
