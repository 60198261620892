import { useRecoilCallback } from 'recoil';

import { assetsFamily, clipsAssetsFamily } from '@store/atoms/AssetsState';
import { userDetailsSelector } from '@store/atoms/AuthState';
import { canvasState, stageState } from '@store/atoms/CanvasState';
import {
  assetIdsState,
  callbackState,
  clipIdsState,
  clipsFamily,
  fontIdsState,
  fontsFamily,
  mergeFamily,
  mergeIdsState,
  overridesFamily,
  trackIdsState,
  viewState,
} from '@store/atoms/EditState';
import { renderPreviewLengthState, renderPreviewStartState } from '@store/atoms/RendersState';
import { idState, nameState } from '@store/atoms/TemplateState';
import { resetOutputCallback } from '@store/studio/Output';

export const useResetStudio = () => {
  return useRecoilCallback((callbackArgs) => {
    const { refresh, reset, snapshot } = callbackArgs;
    return () => {
      reset(idState);
      reset(nameState);
      reset(renderPreviewStartState);
      reset(renderPreviewLengthState);
      reset(viewState);
      reset(canvasState);
      reset(stageState);
      reset(callbackState);

      resetOutputCallback(callbackArgs)();

      const currentMergeIdsState = snapshot.getLoadable(mergeIdsState).contents;
      currentMergeIdsState.forEach((mergeId) => reset(mergeFamily(mergeId)));

      const currentClipIds = snapshot.getLoadable(clipIdsState).contents;
      currentClipIds.forEach((clipId) => {
        reset(clipsFamily(clipId));
        reset(clipsAssetsFamily(clipId));
        reset(overridesFamily(clipId));
      });

      const currentAssetIds = snapshot.getLoadable(assetIdsState).contents;
      currentAssetIds.forEach((assetId) => {
        reset(assetsFamily(assetId));
        reset(overridesFamily(assetId));
      });

      const currentFontIds = snapshot.getLoadable(fontIdsState).contents;
      currentFontIds.forEach((fontId) => reset(fontsFamily(fontId)));

      reset(trackIdsState);
      reset(clipIdsState);
      reset(assetIdsState);
      reset(fontIdsState);
      reset(mergeIdsState);

      refresh(userDetailsSelector);
    };
  }, []);
};
