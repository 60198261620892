import { memo } from 'react';

import { IconDuration, IconStartEnd } from '@assets/icons';

import formatTimecode from '@utils/formatTimeCode';

function TimelineClipInfo({ start, length }) {
  return (
    <div className="clip__info">
      <IconDuration size={12} />
      <span title="Start time">{formatTimecode(start)}</span>
      <IconStartEnd size={12} />
      <span title="End time">{formatTimecode(start + length)}</span>
    </div>
  );
}

export default memo(TimelineClipInfo);
