import { useRecoilState } from 'recoil';

import InputSelect from '@components/controls/InputSelect';

import { outputPresetSelector } from '@store/studio/Output';

import OutputPresets from '@constants/OutputPresets';

function OutputPresetSelect({ tracking }) {
  const [preset, setPreset] = useRecoilState(outputPresetSelector);

  const handleChange = (event) => {
    const { value } = event.target;
    setPreset(value);
  };

  return (
    <InputSelect
      handleChange={handleChange}
      label="Preset"
      selected={preset.value || 'none'}
      tracking={{ ...tracking, name: 'Select Output Preset' }}
      values={OutputPresets}
    />
  );
}

export default OutputPresetSelect;
