import { useRecoilState, useRecoilValue } from 'recoil';

import ColorPicker from '@components/atoms/ColorPicker';

import { backgroundState } from '@store/atoms/EditState';
import { idState } from '@store/atoms/TemplateState';

function SettingBackground() {
  const [background, setBackground] = useRecoilState(backgroundState);
  const templateId = useRecoilValue(idState);

  const tracking = {
    name: 'Change Background Color',
    properties: {
      Id: templateId,
    },
  };

  const handleBackgroundChange = (event) => {
    setBackground(event.target.value);
  };

  return (
    <div>
      <h6>Background Color</h6>
      <ColorPicker className="background" name="background" value={background} handleEvent={handleBackgroundChange} />
    </div>
  );
}

export default SettingBackground;
