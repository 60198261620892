import { atom } from 'recoil';

export const clipChangeState = atom({
  key: 'interactionState',
  default: false,
});

export const interactiveState = atom({
  key: 'interactiveState',
  default: false,
});

export const timelineState = atom({
  key: 'timelineState',
  default: true,
});

export const styleState = atom({
  key: 'styleState',
  default: {
    primary: '#000000',
    secondary: '#fff333',
  },
});

export const originState = atom({
  key: 'originState',
  default: '',
});
