import cn from 'classnames';
import { memo } from 'react';

import { IconImage } from '@assets/icons';

function TimelineClipImage({ clipType, asset }) {
  return (
    <div className={cn('clip__inner', { loading: !asset?.src })}>
      <div className="clip__header">
        <span className="clip__icon">
          <IconImage size={12} />
        </span>
        <span className="clip__type">{clipType}</span>
      </div>
      <div className="clip__sprite" style={{ '--image-url': `url("${asset?.src}")` }} />
    </div>
  );
}

export default memo(TimelineClipImage);
