const determineAssetType = (asset) => {
  const { type } = asset || {};

  if (['text', 'html'].includes(type)) {
    return 'text';
  }

  if (type === 'luma' || type === 'mask') {
    return 'mask';
  }

  if (type === 'audio') {
    return 'audio';
  }

  return type;
};

export default determineAssetType;
