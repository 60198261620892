import { Col, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import OutputFormatSelect from '@feature/studio/setting/output/FormatSelect';
import OutputFrameRateSelect from '@feature/studio/setting/output/FrameRateSelect';
import OutputHeightInput from '@feature/studio/setting/output/HeightInput';
import OutputPresetSelect from '@feature/studio/setting/output/PresetSelect';
import OutputQualitySelect from '@feature/studio/setting/output/QualitySelect';
import OutputScaleToSelect from '@feature/studio/setting/output/ScaleToSelect';
import OutputWidthInput from '@feature/studio/setting/output/WidthInput';

import InputCheckbox from '@components/controls/InputCheckbox';

import { idState } from '@store/atoms/TemplateState';
import { formatAtom, repeatAtom } from '@store/studio/Output';

function SettingOutput() {
  const [repeat, setRepeat] = useRecoilState(repeatAtom);
  const format = useRecoilValue(formatAtom);
  const templateId = useRecoilValue(idState);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  const handleRepeatChange = (event) => {
    const { checked } = event.target;
    setRepeat(checked);
  };

  return (
    <div>
      <h6>Output</h6>

      <Row>
        <Col md={12}>
          <OutputPresetSelect tracking={tracking} />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <OutputWidthInput />
        </Col>
        <Col md={4}>
          <OutputHeightInput />
        </Col>
        <Col md={4}>
          <OutputFormatSelect tracking={tracking} />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <OutputFrameRateSelect tracking={tracking} />
        </Col>
        <Col md={4}>
          <OutputScaleToSelect tracking={tracking} />
        </Col>
        <Col md={4}>
          <OutputQualitySelect tracking={tracking} />
        </Col>
      </Row>
      {format === 'gif' && (
        <Row className="mt-3">
          <Col>
            <InputCheckbox
              label="Repeat"
              checked={repeat}
              handleChange={handleRepeatChange}
              tracking={{
                ...tracking,
                name: 'Check Repeating GIF',
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default SettingOutput;
