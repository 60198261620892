import { selector, selectorFamily } from 'recoil';

import { transformAssetOutgoing } from '@api/transform/utils/assets';

import { assetsFamily, clipsAssetsFamily } from '@store/atoms/AssetsState';
import { activeClipState, clipTypesFamily } from '@store/atoms/ClipState';
import {
  backgroundState,
  cacheState,
  callbackState,
  clipIdsState,
  clipsFamily,
  clipsTracksFamily,
  diskState,
  fontIdsState,
  fontsFamily,
  mergeFamily,
  mergeIdsState,
  overridesFamily,
  soundtrackState,
  trackIdsState,
} from '@store/atoms/EditState';
import { FIRST_FRAME_TIME, playheadState } from '@store/atoms/PlayheadState';
import { mergeReplacementsState } from '@store/selectors/MergeSelectors';
import { outputSelectorFamily } from '@store/studio/Output';

import DefaultFonts from '@utils/fonts';
import { formatMergeReplaceToImplicitType } from '@utils/merge';
import { addHandleBars, jsonStringifyTemplate, removeMetaData, replaceTextOverrideValues } from '@utils/template';

const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$|^\/\/[^\s/$.?#].[^\s]*$/i;
const filterURL = (url) => urlRegex.test(url);

export const clipState = selectorFamily({
  key: 'clipState',
  get:
    (clipId) =>
    ({ get }) =>
      get(clipsFamily(clipId)),

  set:
    (clipId) =>
    ({ get, set }, update) => {
      const clip = clipsFamily(clipId);
      const overrides = get(overridesFamily(clipId));
      const mergeIds = get(mergeIdsState);
      const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));

      // Update merge field value
      Object.keys(overrides).forEach((key) => {
        // Check if the key exists in the update object
        if (update[key]) {
          // Get the corresponding override key
          const overrideKey = overrides[key];
          // Find the merge field with the matching override key and get its ID
          const mergeId = mergeFields.find(({ find }) => find === overrideKey)?.id;
          // Update the merge field with the new value
          set(mergeFamily(mergeId), (prevState) => ({ ...prevState, replace: update[key] }));
        }
      });

      set(clip, (prevState) => ({ ...prevState, ...update }));
    },
});

export const clipVisibilityState = selectorFamily({
  key: 'clipVisibilityState',
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsFamily(clipId));
      const playhead = get(playheadState);
      const { start, length } = clip;

      if (playhead <= FIRST_FRAME_TIME) {
        return start <= FIRST_FRAME_TIME;
      }

      return playhead > start && playhead <= start + length;
    },
});

export const clipAssetState = selectorFamily({
  key: 'clipAssetState',
  get:
    (clipId) =>
    ({ get }) => {
      const assetId = get(clipsAssetsFamily(clipId));
      const asset = get(assetsFamily(assetId));

      if (asset?.type === 'html') {
        const replacements = get(mergeReplacementsState);
        const text = replaceTextOverrideValues(asset?.meta?.text, replacements);
        return { ...asset, text };
      }

      if (asset?.src) {
        try {
          const versionedSrc = new URL(asset.src);
          versionedSrc.searchParams.append('sscache', 1);
          const versionedAsset = { ...asset, src: versionedSrc.href };
          return versionedAsset;
        } catch (error) {
          console.error(error);
          return asset;
        }
      }

      return asset;
    },
  set:
    (clipId) =>
    ({ get, set }, update) => {
      const assetId = get(clipsAssetsFamily(clipId));
      const overrides = get(overridesFamily(assetId));
      const mergeIds = get(mergeIdsState);
      const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));

      // Update merge field value
      Object.keys(overrides).forEach((key) => {
        // Check if the key exists in the update object
        if (update[key]) {
          // Get the corresponding override key
          const overrideKey = overrides[key];
          // Find the merge field with the matching override key and get its ID
          const mergeId = mergeFields.find(({ find }) => find === overrideKey)?.id;
          // Update the merge field with the new value
          set(mergeFamily(mergeId), (prevState) => ({ ...prevState, replace: update[key] }));
        }
      });

      set(assetsFamily(assetId), (prevState) => {
        const { meta: { source, proxied } = {} } = prevState || {};
        const newAsset = { ...prevState, ...update };

        if (update?.src && source && proxied) {
          newAsset.meta = {
            ...(newAsset?.meta || {}),
            source: update.src,
            proxied: false,
          };
        }

        return newAsset;
      });
    },
});

export const activeClipAssetState = selector({
  key: 'activeClipAssetState',
  get: ({ get }) => {
    const clipId = get(activeClipState);
    const clipType = get(clipTypesFamily(clipId));
    const assetId = get(clipsAssetsFamily(clipId));

    return {
      clipId,
      clipType,
      assetId,
    };
  },
});

export const clipTrackState = selectorFamily({
  key: 'clipTrackState',
  get:
    (clipId) =>
    ({ get }) =>
      get(clipsTracksFamily(clipId)),
});

export const trackClipIdsState = selectorFamily({
  key: 'trackClipIdsState',
  get:
    (trackId) =>
    ({ get }) => {
      const clipIds = get(clipIdsState);
      return clipIds
        .map((clipId) => {
          const { trackId: clipTrackId } = get(clipsTracksFamily(clipId));
          return clipTrackId === trackId ? clipId : undefined;
        })
        .filter(Boolean);
    },
});

export const assetListState = selector({
  key: 'assetListState',
  get: ({ get }) => {
    const soundtrack = get(soundtrackState);
    const clipIds = get(clipIdsState);
    const fontIds = get(fontIdsState);

    const assetsList = clipIds.map((clipId) => {
      const asset = get(clipAssetState(clipId));
      return asset?.src;
    });

    const customFontsList = fontIds.map((fontId) => {
      const font = get(fontsFamily(fontId));
      return font.src;
    });

    const defaultFontsList = DefaultFonts.map((font) => {
      return font.src;
    });

    return {
      media: [...assetsList, soundtrack?.src].filter(filterURL),
      fonts: [...defaultFontsList, ...customFontsList].filter(filterURL),
    };
  },
});

export const fontListAllState = selector({
  key: 'fontListAllState',
  get: ({ get }) => {
    const fonts = get(fontIdsState);

    const fontsList = fonts.map((font) => {
      return font.src || null;
    });

    return fontsList;
  },
});

export const fontListByCategoryState = selector({
  key: 'fontListByCategoryState',
  get: ({ get }) => {
    const fontIds = get(fontIdsState);
    const fonts = fontIds
      .map((id) => ({ key: id, ...get(fontsFamily(id)) }))
      .filter(({ src, family }) => Boolean(src) && Boolean(family));

    return { uploaded: fonts, default: DefaultFonts };
  },
});

export const timelineDurationState = selector({
  key: 'timelineDurationState',
  get: ({ get }) => {
    const clipIds = get(clipIdsState);
    const outPoints = clipIds
      .map((clipId) => {
        const clip = get(clipsFamily(clipId));
        return clip.start + clip.length;
      })
      .filter((val) => !Number.isNaN(val));

    const duration = Math.max.apply(null, outPoints);

    if (!Number.isFinite(duration)) {
      return 0.0;
    }

    return parseFloat(duration);
  },
});

export const derivedJsonState = selectorFamily({
  key: 'derivedJsonState',
  get:
    (debug = false) =>
    ({ get }) => {
      const background = get(backgroundState);
      const soundtrackRaw = get(soundtrackState);
      const mergeIds = get(mergeIdsState);
      const callbackRaw = get(callbackState);
      const disk = get(diskState);
      const cache = get(cacheState);
      const fontIds = get(fontIdsState);
      const output = get(outputSelectorFamily(debug));
      const trackIds = get(trackIdsState);
      const clipIds = get(clipIdsState);

      const callbackOverrides = get(overridesFamily('callback'));
      const callback = {
        ...callbackRaw,
        ...(!debug ? addHandleBars(callbackOverrides) : { callbackOverrides }),
      };

      const soundtrackOverrides = get(overridesFamily('soundtrack'));
      const soundtrack = {
        ...soundtrackRaw,
        ...(!debug ? addHandleBars(soundtrackOverrides) : { soundtrackOverrides }),
      };

      const tracks = clipIds
        .reduce(
          (acc, clipId) => {
            const { trackIndex } = get(clipsTracksFamily(clipId));
            const clipRaw = get(clipsFamily(clipId));
            const clipOverrides = get(overridesFamily(clipId));
            const clip = { ...clipRaw, ...(!debug ? addHandleBars(clipOverrides) : { clipOverrides }) };

            const assetId = get(clipsAssetsFamily(clipId));
            const assetRaw = get(assetsFamily(assetId));
            const assetOverrides = get(overridesFamily(assetId));

            const assetWithOverrides = {
              ...assetRaw,
              ...(!debug ? addHandleBars(assetOverrides) : { assetOverrides }),
            };

            const asset = !debug ? transformAssetOutgoing(assetWithOverrides) : assetWithOverrides;
            const clipEntry = !debug ? { asset: removeMetaData(asset), ...removeMetaData(clip) } : { asset, ...clip };

            acc[trackIndex] = acc[trackIndex] || { clips: [] };
            acc[trackIndex].clips.push(clipEntry);
            return acc;
          },
          trackIds.map(() => ({ clips: [] }))
        )
        .filter(({ clips }) => clips.length > 0);

      const merge = mergeIds
        .map((mergeId) => {
          const mergeField = get(mergeFamily(mergeId));
          const replace = formatMergeReplaceToImplicitType(mergeField.replace, mergeField?.meta?.type);
          const newField = { ...mergeField, replace: replace ?? '' };
          return !debug ? removeMetaData(newField) : newField;
        })
        .filter(({ find }) => Boolean(find));

      let fonts = fontIds.map((fontId) => get(fontsFamily(fontId)));
      if (!debug) {
        fonts = fontIds
          .map((fontId) => {
            const { src, meta } = get(fontsFamily(fontId));
            return meta?.src ?? src;
          })
          .filter(Boolean)
          .map((src) => ({ src }));
      }

      return jsonStringifyTemplate({
        background,
        soundtrack: !debug ? removeMetaData(soundtrack) : soundtrack,
        merge,
        callback: !debug ? callback?.src || '' : callback,
        disk,
        cache,
        fonts,
        output,
        tracks,
      });
    },
});
