import { Container as PixiContainer } from '@inlet/react-pixi';
import { useRecoilValue } from 'recoil';

import { STUDIO_TRACK_HIDE_ENABLED } from '@src/featureFlags';

import CanvasClip from '@feature/studio/canvas/CanvasClip';

import { trackVisibleState } from '@store/atoms/EditState';
import { trackClipIdsState } from '@store/selectors/EditSelectors';

const getClipIndex = (trackIndex, clipIndex) => {
  const index = trackIndex + clipIndex / 100;
  return index;
};

function CanvasTrack({ id, index: trackIndex }) {
  const clipIds = useRecoilValue(trackClipIdsState(id));
  const trackVisible = useRecoilValue(trackVisibleState(id));
  const trackClips = clipIds.slice().reverse().filter(Boolean);

  if (STUDIO_TRACK_HIDE_ENABLED) {
    return (
      <PixiContainer visible={trackVisible}>
        {trackClips.map((clipId, clipIndex) => (
          <CanvasClip key={clipId} id={clipId} index={getClipIndex(trackIndex, clipIndex)} />
        ))}
      </PixiContainer>
    );
  }

  return trackClips.map((clipId, clipIndex) => (
    <CanvasClip key={clipId} id={clipId} index={getClipIndex(trackIndex, clipIndex)} />
  ));
}

export default CanvasTrack;
