import {
  slideDownMotionKeyframe,
  slideLeftMotionKeyframe,
  slideRightMotionKeyframe,
  slideUpMotionKeyframe,
} from '@animation/preset/motion/slide';
import { zoomInMotionKeyframe, zoomOutMotionKeyframe } from '@animation/preset/motion/zoom';

/**
 * @typedef {'zoomIn' | 'zoomOut' | 'slideLeft' | 'slideRight' | 'slideUp' | 'slideDown'} MotionEffect
 * @typedef {'normal' | 'fast' | 'slow'} MotionEffectSpeed
 */

/**
 * @type {(preset: string) => ({ motionEffect: MotionEffect, motionEffectSpeed: MotionEffectSpeed })}
 */
export const parseMotionEffect = (preset) => {
  /** @ts-ignore @type {MotionEffect} */
  const motionEffect = preset.split(/Fast|Slow/).shift();

  /** @type {MotionEffectSpeed} */
  let motionEffectSpeed = 'normal';
  if (preset.endsWith('Fast')) motionEffectSpeed = 'fast';
  if (preset.endsWith('Slow')) motionEffectSpeed = 'slow';

  return { motionEffect, motionEffectSpeed };
};

/**
 * @type {(motionEffect: MotionEffect) => (start: number, end: number, properties: import("./animate").AnimationProperties) => import('@animation/keyframe').Keyframe<{}>}
 */
export const getMotionEffectKeyframePreset = (motionEffect) => {
  switch (motionEffect) {
    case 'zoomIn':
      return zoomInMotionKeyframe;
    case 'zoomOut':
      return zoomOutMotionKeyframe;
    case 'slideLeft':
      return slideLeftMotionKeyframe;
    case 'slideRight':
      return slideRightMotionKeyframe;
    case 'slideUp':
      return slideUpMotionKeyframe;
    case 'slideDown':
      return slideDownMotionKeyframe;
    default:
      return null;
  }
};
