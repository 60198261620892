import { useRecoilState } from 'recoil';

import InputSelect from '@components/controls/InputSelect';

import { scaleToAtom } from '@store/studio/Output';

import Resolutions from '@constants/Resolutions';

const SCALE_TO_OPTIONS = [
  {
    value: '',
    text: 'No Scaling',
  },
  ...Resolutions,
];

const DEFAULT_SCALE_TO = '';

function ScaleToSelect({ tracking }) {
  const [scaleTo, setScaleTo] = useRecoilState(scaleToAtom);

  const handleScaleToChange = (event) => {
    const { value } = event.target;
    setScaleTo(value || DEFAULT_SCALE_TO);
  };

  return (
    <InputSelect
      handleChange={handleScaleToChange}
      label="Scale To"
      selected={scaleTo || DEFAULT_SCALE_TO}
      tracking={{
        ...tracking,
        name: 'Select Output Scale To',
      }}
      values={SCALE_TO_OPTIONS}
    />
  );
}

export default ScaleToSelect;
