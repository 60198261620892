import immutableUpdate from 'immutability-helper';
import { atom, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { assetsFamily, clipsAssetsFamily } from '@store/atoms/AssetsState';
import { activeClipState, clipTypesFamily } from '@store/atoms/ClipState';
import { assetIdsState, clipIdsState, clipsFamily, clipsTracksFamily, trackIdsState } from '@store/atoms/EditState';
import { clipAssetState, trackClipIdsState } from '@store/selectors/EditSelectors';

import { ASSET_TYPES_MASK } from '@constants/AssetTypes';
import { TIMELINE_SCALE_DEFAULT } from '@constants/Timeline';

import determineAssetType from '@utils/editor/determineAssetType';
import roundToPrecision from '@utils/math/roundToPrecision';
import { getSnapshot } from '@utils/recoil';
import {
  getClipsGroupedByIntersect,
  multipleIntersectingClips,
  singleIntersectingClip,
  updateTrackClips,
} from '@utils/timeline';
import { getClipStartTime, getMaxClipLength } from '@utils/tracks';

export const timelineScaleState = atom({
  key: 'timelineScaleState',
  default: TIMELINE_SCALE_DEFAULT,
});

export const useAddTrackState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const trackId = uuid();
        const trackIds = snapshot.getLoadable(trackIdsState).contents;
        const newTrackIds = [trackId, ...trackIds];

        set(trackIdsState, newTrackIds);

        // update each clip's trackIndex to match new trackIds
        const clipIds = snapshot.getLoadable(clipIdsState).contents;
        clipIds.forEach((clipId) => {
          const clipTrackId = snapshot.getLoadable(clipsTracksFamily(clipId)).contents.trackId;
          const clipTrackIndex = newTrackIds.findIndex((id) => id === clipTrackId);
          set(clipsTracksFamily(clipId), (currentState) => ({ ...currentState, trackIndex: clipTrackIndex }));
        });
      },
    []
  );
};

export const useAddClipState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (toTrackId, asset) => {
        const newClipId = uuid();
        const newAssetId = uuid();

        const clipAssetType = determineAssetType(asset);
        const clipProperties = {
          id: newClipId,
          start: 0,
          length: 5,
        };

        set(clipIdsState, (currentState) => [...currentState, newClipId]);
        set(assetIdsState, (currentState) => [...currentState, newAssetId]);
        set(clipsAssetsFamily(newClipId), newAssetId);
        set(assetsFamily(newAssetId), asset);

        const toTrackIndex = snapshot.getLoadable(trackIdsState).contents.findIndex((id) => id === toTrackId);
        const toTrackClipIds = snapshot.getLoadable(trackClipIdsState(toTrackId)).contents;
        const toTrackClips = toTrackClipIds.map((clipId) => {
          const clipSnapshot = getSnapshot({ snapshot, id: clipId, family: clipsFamily });
          const clipSnapshotType = getSnapshot({ snapshot, id: clipId, family: clipTypesFamily });
          return { ...clipSnapshot, type: clipSnapshotType };
        });
        clipProperties.start = getClipStartTime(toTrackClips, clipProperties, clipAssetType);

        set(clipsFamily(newClipId), clipProperties);
        set(clipTypesFamily(newClipId), clipAssetType);
        set(clipsTracksFamily(newClipId), { trackId: toTrackId, trackIndex: toTrackIndex });

        return clipProperties;
      },
    []
  );
};

export const useDeleteTrackState = () => {
  return useRecoilCallback(
    ({ set, reset, snapshot }) =>
      (trackId) => {
        const trackClipIds = snapshot.getLoadable(trackClipIdsState(trackId)).contents;

        set(clipIdsState, (currentState) => {
          const newClipIds = currentState.filter((clipId) => !trackClipIds.includes(clipId));
          return newClipIds;
        });

        trackClipIds.forEach((clipId) => {
          reset(clipsFamily(clipId));
          reset(clipTypesFamily(clipId));
          reset(clipsTracksFamily(clipId));
        });

        const trackIds = snapshot.getLoadable(trackIdsState).contents;
        const trackIndex = trackIds.indexOf(trackId);
        const newTrackIds = [...trackIds.slice(0, trackIndex), ...trackIds.slice(trackIndex + 1)];

        set(trackIdsState, newTrackIds);

        const shuffledTracks = newTrackIds.slice(trackIndex, newTrackIds.length);
        shuffledTracks.forEach((shuffledTrackId) => {
          const shuffledTrackIndex = newTrackIds.indexOf(shuffledTrackId);
          const shuffledTrackClipIds = snapshot.getLoadable(trackClipIdsState(shuffledTrackId)).contents;

          shuffledTrackClipIds.forEach((shuffledTrackClipId) => {
            set(clipsTracksFamily(shuffledTrackClipId), { trackId: shuffledTrackId, trackIndex: shuffledTrackIndex });
          });
        });
      },
    []
  );
};

export const useDeleteClipState = () => {
  return useRecoilCallback(
    ({ set, reset }) =>
      (clipId) => {
        set(clipIdsState, (currentState) => {
          return currentState.filter((id) => id !== clipId);
        });

        reset(clipsFamily(clipId));
        reset(clipTypesFamily(clipId));
        reset(clipsTracksFamily(clipId));
        reset(activeClipState);
      },
    []
  );
};

export const useMoveClipState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId, newStartTime, toTrackId, toTrackIndex) => {
        const clip = getSnapshot({ snapshot, id: clipId, family: clipsFamily });
        const clipType = getSnapshot({ snapshot, id: clipId, family: clipTypesFamily });
        const newClip = { ...clip, start: newStartTime };

        if (clipType !== 'mask') {
          const clips = getSnapshot({ snapshot, id: toTrackId, family: trackClipIdsState })
            .map((id) => {
              const clipSnapshot = getSnapshot({ snapshot, id, family: clipsFamily });
              const clipAsset = getSnapshot({ snapshot, id, family: clipAssetState });
              return { id, ...clipSnapshot, asset: clipAsset };
            })
            .filter(
              (filteredClip) => !ASSET_TYPES_MASK.includes(filteredClip?.asset?.type) && filteredClip.id !== clipId
            );

          const { intersecting, complements } = getClipsGroupedByIntersect(clips, newClip);

          if (intersecting.length) {
            let startGap = 0;

            if (intersecting.length > 1) {
              const { newStart, gap } = multipleIntersectingClips({ intersecting, newClip });
              newClip.start = roundToPrecision(newStart);
              startGap = gap;
            } else if (intersecting.length === 1) {
              const { newStart, gap } = singleIntersectingClip({ intersecting, complements, newClip });
              newClip.start = roundToPrecision(newStart);
              startGap = gap || startGap;
            }

            updateTrackClips({
              set,
              clips,
              family: clipsFamily,
              newClip,
              startCallback: (startTime) => roundToPrecision(startTime + startGap),
            });
          }
        }

        set(clipsTracksFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });
        set(clipsFamily(clipId), newClip);

        return newClip;
      },
    []
  );
};

export const useUpdateClipLengthState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId, newLength) => {
        const clip = getSnapshot({ snapshot, id: clipId, family: clipsFamily });
        const newClip = { ...clip };

        const { trackId } = getSnapshot({ snapshot, id: clipId, family: clipsTracksFamily });
        const trackClipIds = getSnapshot({ snapshot, id: trackId, family: trackClipIdsState });
        const trackClips = trackClipIds.map((trackClipId) =>
          getSnapshot({ snapshot, id: trackClipId, family: clipsFamily })
        );

        newClip.length = getMaxClipLength(trackClips, clip, newLength);
        set(clipsFamily(clipId), newClip);
      },
    []
  );
};

export const useResetClipState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId, toTrackId) => {
        const toTrackIndex = snapshot.getLoadable(trackIdsState).contents.findIndex((id) => id === toTrackId);
        set(clipsTracksFamily(clipId), { trackId: -1, trackIndex: -1 });
        // hack to trick react into re-rendering the clip
        const resetClipTimeout = setTimeout(() => {
          clearTimeout(resetClipTimeout);
          set(clipsTracksFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });
        }, 10);
      },
    []
  );
};

export const useMoveTrackState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (fromIndex, toIndex) => {
        // move tracks and cache new trackIds
        const trackIds = snapshot.getLoadable(trackIdsState).contents;
        const newTrackIds = immutableUpdate(trackIds, {
          $splice: [
            [fromIndex, 1],
            [toIndex, 0, trackIds[fromIndex]],
          ],
        });
        set(trackIdsState, newTrackIds);

        // update each clip's trackIndex to match new trackIds
        const clipIds = snapshot.getLoadable(clipIdsState).contents;
        clipIds.forEach((clipId) => {
          const clipTrackId = snapshot.getLoadable(clipsTracksFamily(clipId)).contents.trackId;
          const clipTrackIndex = newTrackIds.findIndex((id) => id === clipTrackId);
          set(clipsTracksFamily(clipId), (currentState) => ({ ...currentState, trackIndex: clipTrackIndex }));
        });
      },
    []
  );
};
