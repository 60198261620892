import { useEffect } from 'react';
import Draggable from 'react-draggable';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import '@css/TimelinePlayhead.css';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { timelineScaleState } from '@store/atoms/TimelineState';

import { TIMELINE_OFFSET } from '@constants/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';
import { isNumber } from '@utils/isNumber';

function TimelinePlayheadReactable({ children }) {
  const { playhead, timelineDuration, isPlayable, isPlaying, handlePause, setPlayhead } = useTimelinePlaybackContext();
  const [searchParams] = useSearchParams();
  const timelineScale = useRecoilValue(timelineScaleState);
  const pixelSeconds = getPixelSeconds(timelineScale);

  const handleDrag = (_, data) => {
    if (isPlaying) handlePause();
    const newPlayheadPosition = (data.x - TIMELINE_OFFSET) / timelineScale;
    setPlayhead(Math.max(0, newPlayheadPosition));
  };

  useEffect(() => {
    if (searchParams.has('t')) {
      const startInSeconds = parseFloat(searchParams.get('t'));
      if (isNumber(startInSeconds) && startInSeconds >= 0) {
        setPlayhead(startInSeconds);
      }
    }
  }, [searchParams, timelineDuration]);

  if (!isPlayable || !timelineDuration) {
    return null;
  }

  const trackLeftBound = Math.max(TIMELINE_OFFSET, pixelSeconds(playhead) + TIMELINE_OFFSET);
  const position = { x: Math.min(trackLeftBound, pixelSeconds(timelineDuration) + TIMELINE_OFFSET), y: 0 };

  return (
    <Draggable axis="none" defaultPosition={{ x: TIMELINE_OFFSET, y: 0 }} position={position} onDrag={handleDrag}>
      {children}
    </Draggable>
  );
}

export default TimelinePlayheadReactable;
