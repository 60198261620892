const responseInterceptors = [
  (response) => {
    return response;
  },
  (error) => {
    if (!error.toJSON) {
      return Promise.reject(error);
    }

    const errorJson = error.toJSON();
    const { status, config } = errorJson;
    if (status === 404 && config.url === 'templates') {
      return Promise.resolve({ data: [] });
    }

    return Promise.reject(error);
  },
];

export default responseInterceptors;
