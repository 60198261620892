/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; }>}
 */
export const carouselLeftTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = canvas.width * (clip.position.x / canvas.width + slidePercent);
  const slideTo = clip.position.x;

  return {
    from: {
      x: slideFrom,
    },
    to: {
      x: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; }>}
 */
export const carouselLeftTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.x;
  const slideTo = canvas.width * (clip.position.x / canvas.width - slidePercent);

  return {
    from: {
      x: slideFrom,
    },
    to: {
      x: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; }>}
 */
export const carouselRightTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = canvas.width * (clip.position.x / canvas.width - slidePercent);
  const slideTo = clip.position.x;

  return {
    from: {
      x: slideFrom,
    },
    to: {
      x: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ x: number; }>}
 */
export const carouselRightTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.x;
  const slideTo = canvas.width * (clip.position.x / canvas.width + slidePercent);

  return {
    from: {
      x: slideFrom,
    },
    to: {
      x: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; }>}
 */
export const carouselUpTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = canvas.height * (clip.position.y / canvas.height + slidePercent);
  const slideTo = clip.position.y;

  return {
    from: {
      y: slideFrom,
    },
    to: {
      y: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; }>}
 */
export const carouselUpTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.y;
  const slideTo = canvas.height * (clip.position.y / canvas.height - slidePercent);

  return {
    from: {
      y: slideFrom,
    },
    to: {
      y: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; }>}
 */
export const carouselDownTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = canvas.height * (clip.position.y / canvas.height - slidePercent);
  const slideTo = clip.position.y;

  return {
    from: {
      y: slideFrom,
    },
    to: {
      y: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ y: number; }>}
 */
export const carouselDownTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 1;

  const { clip, canvas } = properties;

  const slideFrom = clip.position.y;
  const slideTo = canvas.height * (clip.position.y / canvas.height + slidePercent);

  return {
    from: {
      y: slideFrom,
    },
    to: {
      y: slideTo,
    },
    start,
    end,
    interpolation: 'linear',
  };
};
