const calculateHandlePosition = (params) => {
  const { dimensions, position } = params;

  const { x, y } = position;
  const { height, width } = dimensions;

  return {
    topLeft: {
      x: x - width / 2,
      y: y - height / 2,
    },
    top: {
      x,
      y: y - height / 2,
    },
    topRight: {
      x: x + width / 2,
      y: y - height / 2,
    },
    right: {
      x: x + width / 2,
      y,
    },
    bottomRight: {
      x: x + width / 2,
      y: y + height / 2,
    },
    bottom: {
      x,
      y: y + height / 2,
    },
    bottomLeft: {
      x: x - width / 2,
      y: y + height / 2,
    },
    left: {
      x: x - width / 2,
      y,
    },
  };
};

export default calculateHandlePosition;
