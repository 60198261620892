import { Sound } from '@pixi/sound';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { playheadIsPausedState, playheadIsStoppedState, playheadState } from '@store/atoms/PlayheadState';
import { clipAssetState, clipState, clipVisibilityState } from '@store/selectors/EditSelectors';

import roundToPrecision from '@utils/math/roundToPrecision';

function CanvasAudioPlayer({ id }) {
  const asset = useRecoilValue(clipAssetState(id));
  const clip = useRecoilValue(clipState(id));
  const playhead = useRecoilValue(playheadState);
  const isPaused = useRecoilValue(playheadIsPausedState);
  const isStopped = useRecoilValue(playheadIsStoppedState);
  const [sound, setSound] = useState();
  const { start } = clip;
  const { trim, src: audioSrc } = asset;
  const visible = useRecoilValue(clipVisibilityState(id));

  useEffect(() => {
    try {
      if (!audioSrc) {
        throw new Error('No audio source');
      }

      const soundSrc = new URL(audioSrc).href;
      const pixiSound = Sound.from({
        url: soundSrc,
        preload: true,
        singleInstance: true,
      });

      setSound(pixiSound);
    } catch (error) {
      console.error(error);
    }

    return () => {
      sound?.destroy();
      setSound();
    };
  }, [audioSrc]);

  const play = useCallback(
    ({ start: startTime }) => {
      if (!sound?.isLoaded) return;
      sound.play({
        start: startTime,
        volume: asset?.volume >= 0 ? asset?.volume : 0,
      });
    },
    [asset?.volume, sound]
  );

  const stop = useCallback(() => {
    if (!sound?.isLoaded || !sound?.isPlaying) return;
    sound.stop();
  }, [sound]);

  useEffect(() => {
    try {
      if (sound?.isPlaying && (!visible || isPaused || isStopped)) {
        stop();
        return;
      }

      if (visible && !isPaused && !isStopped) {
        const soundStart = (trim || 0) + roundToPrecision(playhead - start);
        play({ start: soundStart });
      }
    } catch (error) {
      console.error(error);
    }
  }, [visible, isPaused, isStopped]);

  return null;
}

export default CanvasAudioPlayer;
