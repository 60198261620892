import { atom } from 'recoil';

export const errorState = atom({
  key: 'errorState',
  default: {
    type: 'error',
    title: '',
    message: '',
  },
});
