import { interpolateKeyframe } from '@animation/keyframe';
import { getMotionEffectKeyframePreset, parseMotionEffect } from '@animation/motion';
import { getTransitionDuration, getTransitionKeyframePreset, parseTransition } from '@animation/transition';

/**
 * @typedef {{
 *  canvas: {
 *    width: number;
 *    height: number;
 *  }
 *  clip: {
 *    type: 'html' | 'video' | 'image';
 *    scale?: number;
 *    width: number;
 *    height: number;
 *    position: {
 *      x: number;
 *      y: number;
 *    }
 *    opacity: number
 *  }
 *  speed?: 'normal' | 'fast' | 'slow'
 * }} AnimationProperties
 */

/**
 * @template {Record<string, number>} T
 *
 * @type {(props: { keyframes: import('@animation/keyframe').Keyframe<T>[], time: number }) => Record<string, number>}
 */
const createInterpolatedValues = (props) => {
  if (!props.keyframes.length) return {};

  const { keyframes, time } = props;

  return keyframes.reduce((acc, keyframe) => {
    const { start, end } = keyframe;
    const isKeyFrameActive = start <= time && end >= time;
    if (!isKeyFrameActive) return acc;

    const newInterpolatedValues = interpolateKeyframe(keyframe, time);
    return { ...acc, ...newInterpolatedValues };
  }, {});
};

/**
 * @typedef {{ start: number, length: number, scale?: number, effect: import('./motion').MotionEffect, transition?: { in?: import('./transition').Transition, out?: import('./transition').Transition }, opacity: number }} Clip
 * @typedef {{ position: { x: number, y: number }, canvasWidth: number, canvasHeight: number, dimensions: { width: number, height: number, aspectRatio: number, scale: number } }} MovableAsset
 *
 * @typedef {{ type: import('@animation/animate').AnimationProperties['clip']['type'], clip: Clip, movableAsset: MovableAsset, playhead: number }} CreateClipAnimationConfig
 * @type {(config: CreateClipAnimationConfig) => Record<string, number>}
 */
export const createClipAnimation = (config) => {
  const { type, clip, asset, playhead, movableAsset } = config;

  const { start, length: clipLength, effect = '', opacity, transition = {} } = clip;
  const { position, dimensions, canvasWidth, canvasHeight } = movableAsset;

  const canvasAnimationProperties = { width: canvasWidth, height: canvasHeight };
  const { width, height, aspectRatio } = dimensions;

  let scale = dimensions.scale;
  if (asset?.meta?.proxied && asset?.meta?.proxyScaleMultiplier) {
    scale = dimensions.scale * asset.meta.proxyScaleMultiplier;
  }

  const originalWidth = width / aspectRatio / scale;
  const originalHeight = height / aspectRatio / scale;
  const originalScale = aspectRatio * scale;

  const clipAnimationProperties = {
    type,
    width: originalWidth,
    height: originalHeight,
    scale: originalScale,
    position,
    opacity,
  };

  const animationProperties = { canvas: canvasAnimationProperties, clip: clipAnimationProperties };

  /** @type {import('@animation/keyframe').Keyframe<any>[]} */
  const keyframes = [];

  const { motionEffect, motionEffectSpeed } = parseMotionEffect(effect);
  const motionEffectKeyframePreset = getMotionEffectKeyframePreset(motionEffect);

  if (motionEffectKeyframePreset) {
    keyframes.push(motionEffectKeyframePreset(0, clipLength, { ...animationProperties, speed: motionEffectSpeed }));
  }

  if (transition.in) {
    const { transition: transitionIn, transitionSpeed: transitionInSpeed } = parseTransition(transition.in);
    const transitionInDuration = getTransitionDuration(transitionIn, transitionInSpeed);
    const transitionInKeyframePreset = getTransitionKeyframePreset(transitionIn, 'in');

    if (transitionInKeyframePreset)
      keyframes.push(transitionInKeyframePreset(0, transitionInDuration, animationProperties));
  }

  if (transition.out) {
    const { transition: transitionOut, transitionSpeed: transitionOutSpeed } = parseTransition(transition.out);
    const transitionOutDuration = getTransitionDuration(transitionOut, transitionOutSpeed);
    const transitionOutKeyframePreset = getTransitionKeyframePreset(transitionOut, 'out');

    if (transitionOutKeyframePreset)
      keyframes.push(transitionOutKeyframePreset(clipLength - transitionOutDuration, clipLength, animationProperties));
  }

  return createInterpolatedValues({
    keyframes,
    time: playhead - start,
  });
};
