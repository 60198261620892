export const jsonStringifyTemplate = ({
  background,
  soundtrack,
  merge,
  callback,
  disk,
  cache,
  fonts,
  output,
  tracks,
}) => {
  let timeline = { background };
  let edit = { timeline, output };

  if (merge?.length) edit = { ...edit, merge };
  if (callback) edit = { ...edit, callback };
  if (disk === 'mount') edit = { ...edit, disk };
  if (cache === false) timeline = { ...timeline, cache };
  if (fonts?.length) timeline = { fonts, ...timeline };
  if (soundtrack?.src) timeline = { soundtrack, ...timeline };
  if (tracks.length) edit = { ...edit, timeline: { ...timeline, tracks } };

  return JSON.stringify(edit, null, 4);
};

export const replaceTextOverrideValues = (text, replacements) => {
  return Object.entries(replacements).reduce((acc, [find, replacement]) => {
    const regex = new RegExp(`{{\\s*${find}\\s*}}`, 'gi');
    return acc.replace ? acc.replace(regex, replacement) : acc;
  }, text || '');
};

export const removeMetaData = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const { id, meta, ...restData } = item || {};
      return restData;
    });
  } else {
    const { id, meta, ...restData } = data || {};
    return restData;
  }
};

export const addHandleBars = (data) => {
  return Object.entries(data).reduce((acc, [find, replace]) => {
    acc[find] = `{{ ${replace} }}`;
    return acc;
  }, {});
};

export const removeEmptyEntries = (obj) => {
  if (typeof obj !== 'object' || obj === null) return obj;

  if (Array.isArray(obj)) {
    return obj.filter((item) => {
      if (typeof item === 'object' && item !== null) {
        return Object.keys(removeEmptyEntries(item)).length > 0;
      }
      return item !== '';
    });
  }

  const cleanedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (Array.isArray(value) && value.length === 0) {
        continue;
      }

      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        const nestedCleaned = removeEmptyEntries(value);
        if (Object.keys(nestedCleaned).length > 0) {
          cleanedObj[key] = nestedCleaned;
        }
      } else if (value !== '') {
        cleanedObj[key] = value;
      }
    }
  }

  return cleanedObj;
};
