import CanvasMask from './CanvasMask';
import { createClipAnimation } from '@animation/animate';
import {
  BOUNDING_BOX_HEIGHT_PIXELS,
  BOUNDING_BOX_WIDTH_PIXELS,
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  TEXT_ALIGN,
} from '@constants/TextAssetDefaults';
import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';
import CanvasEditFrame from '@feature/studio/canvas/CanvasEditFrame';
import useFontCheck from '@hooks/useFontCheck';
import useMovableAsset from '@hooks/useMovableAsset';
import { Container, Graphics, Text } from '@inlet/react-pixi';
import { clipsFamily } from '@store/atoms/EditState';
import { interactiveState } from '@store/atoms/InteractionState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';
import { activeClipAssetState, clipAssetState, clipState, clipVisibilityState } from '@store/selectors/EditSelectors';
import formatWebGlColor from '@utils/formatWebGlColor';
import { TextStyle } from 'pixi.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const BASE_AUTO_FONT_WIDTH_RATIO = 0.9;
const MIN_AUTO_FONT_SIZE = 8;
const MAX_AUTO_FONT_SIZE = 512;
const LINE_SPACING_RATIO = 0.5;
const ALLOWANCE_RATIO = 0.3;
/**
 * @type {(content: string, boundingBox: { width: number; height: number }, initialSize: number) => number}
 */
const calculateFontSize = (content, boundingBox, initialSize) => {
  let fontSize = initialSize;
  const maxCapacity = boundingBox.width * boundingBox.height;
  while (fontSize > MIN_AUTO_FONT_SIZE) {
    // TODO: opentype algo - glyph.advanceWidth * fontSize / font.unitsPerEm;
    const fontWidth = fontSize * BASE_AUTO_FONT_WIDTH_RATIO;
    const fontHeight = fontSize;
    const lineHeight = fontHeight + fontHeight * LINE_SPACING_RATIO;
    const words = content.split(' ');
    let currentLineWidth = 0;
    let totalHeight = lineHeight;
    words.forEach((word) => {
      const wordWidth = word.length * fontWidth;
      if (currentLineWidth + wordWidth > boundingBox.width) {
        totalHeight += lineHeight;
        currentLineWidth = 0;
      }
      currentLineWidth += wordWidth + fontWidth;
    });
    let capacity = totalHeight * boundingBox.width;
    capacity += capacity * ALLOWANCE_RATIO;
    if (capacity <= maxCapacity) break;
    fontSize -= 1;
  }
  return Math.min(Math.max(MIN_AUTO_FONT_SIZE, fontSize), MAX_AUTO_FONT_SIZE);
};

function CanvasHtmlPlayer({ id, index }) {
  const spriteRef = useRef();
  const canvas = useRecoilValue(canvasStageState({}));
  const [asset, setAsset] = useRecoilState(clipAssetState(id));
  const clip = useRecoilValue(clipState(id));
  const { clipId: activeClipId } = useRecoilValue(activeClipAssetState);
  const setClip = useSetRecoilState(clipsFamily(id));
  const isFontLoaded = useFontCheck(asset?.fontFamily);
  const isInteractive = useRecoilValue(interactiveState);

  /** @type {ReturnType<typeof import('react').useState<{ [key: string]: any }>>} */
  const [textAnchorX, setTextAnchorX] = useState(0.5);
  const [textAnchorY, setTextAnchorY] = useState(0.5);
  const [textOffsetX, setTextOffsetX] = useState(0);
  const [textOffsetY, setTextOffsetY] = useState(0);
  const visible = useRecoilValue(clipVisibilityState(id));
  const { playhead } = useTimelinePlaybackContext();

  // @ts-ignore
  const { opacity = 1 } = clip;
  // @ts-ignore
  const {
    text,
    height,
    width,
    background,
    color,
    textAlign,
    fontFamily,
    fontSize,
    fontWeight,
    fontStyle,
    fontVariant,
    lineHeight,
    position: htmlPosition,
  } = asset;

  let dynamicFontSize = fontSize || FONT_SIZE;

  const isSelected = activeClipId === id;

  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const assetWidth = width || BOUNDING_BOX_WIDTH_PIXELS;
  const assetHeight = height || BOUNDING_BOX_HEIGHT_PIXELS;

  // prettier-ignore
  const movableAssetConfig = { id, assetWidth, assetHeight, canvasWidth, canvasHeight, clip: { ...clip, fit: 'none', scale: 1 }, setClip, asset, setAsset, spriteRef };
  const movableAsset = useMovableAsset(movableAssetConfig);
  // prettier-ignore
  // eslint-disable-next-line max-len
  const { bounds, dimensions, handlePositionGrab, handlePositionDrop, handlePositionDrag, handleResizeDrag, handleResizeDrop, handleResizeGrab, isMoving, position, scale } = movableAsset;
  // @ts-ignore
  const clipAnimation = createClipAnimation({ type: 'html', clip, playhead, movableAsset });

  let bgColor = null;
  let bgOpacity = 0.0001; // if bgOpacity is 0, element is not interactive, can not be dragged.

  // todo: check background is valid hex color - #fff, #ffffff or #ccffffff
  if (background && background !== 'transparent') {
    bgColor = formatWebGlColor(background);
    bgOpacity = 1;
  }

  const boundingBox = useCallback(
    (graphics) => {
      graphics.clear();
      graphics.beginFill(bgColor, bgOpacity);
      graphics.drawRect(-dimensions.width / 2, -dimensions.height / 2, dimensions.width, dimensions.height);
      graphics.endFill();
    },
    [bgColor, bgOpacity, dimensions.width, dimensions.height]
  );
  const boundingBoxMaskRef = useRef();

  useEffect(() => {
    switch (textAlign) {
      case 'left':
        setTextAnchorX(0);
        setTextOffsetX(-dimensions.width / 2);
        break;
      case 'right':
        setTextAnchorX(1);
        setTextOffsetX(dimensions.width / 2);
        break;
      default:
        setTextAnchorX(0.5);
        setTextOffsetX(0);
        break;
    }
  }, [textAlign, dimensions.width]);

  useEffect(() => {
    switch (htmlPosition) {
      case 'center':
        setTextAnchorY(0.5);
        setTextOffsetY(0);
        break;
      case 'top':
        setTextAnchorY(0);
        setTextOffsetY(-dimensions.height / 2);
        break;
      case 'bottom':
        setTextAnchorY(1);
        setTextOffsetY(dimensions.height / 2);
        break;
      default:
        setTextAnchorY(0.5);
        setTextOffsetY(0);
        break;
    }
  }, [dimensions.height, htmlPosition]);

  if (asset?.textScale === 'shrink') {
    dynamicFontSize = calculateFontSize(text, { width: dimensions.width, height: dimensions.height }, fontSize);
  }

  const textStyle = new TextStyle({
    align: textAlign || TEXT_ALIGN,
    fontFamily: fontFamily || FONT_FAMILY,
    fontSize: `${dynamicFontSize}px`,
    fontWeight: fontWeight || 'normal',
    fill: color || COLOR,
    fontStyle: fontStyle || 'normal',
    fontVariant: fontVariant || 'normal',
    lineHeight: lineHeight || dynamicFontSize * 1.3,
    wordWrap: true,
    wordWrapWidth: dimensions.width || 1,
    padding: 100,
    textBaseline: 'alphabetic',
  });

  if (!text || !isFontLoaded) {
    return null;
  }

  const contents = (
    <>
      <Container
        ref={spriteRef}
        visible={visible}
        x={position.x}
        y={position.y}
        width={dimensions.width || 1}
        height={dimensions.height || 1}
        alpha={opacity}
        scale={scale}
        mask={boundingBoxMaskRef?.current}
        zIndex={index}
        interactive
        {...clipAnimation}
      >
        <Graphics draw={boundingBox} />
        <Graphics name='mask' draw={boundingBox} ref={boundingBoxMaskRef} />
        <Text text={text} anchor={[textAnchorX, textAnchorY]} x={textOffsetX} y={textOffsetY} style={textStyle} />
      </Container>
      {isInteractive && (
        <CanvasEditFrame
          bounds={bounds}
          width={dimensions.width || 1}
          height={dimensions.height || 1}
          isDragging={isMoving}
          handlePositionGrab={handlePositionGrab}
          handlePositionDrag={handlePositionDrag}
          handlePositionDrop={handlePositionDrop}
          handleResizeGrab={handleResizeGrab}
          handleResizeDrag={handleResizeDrag}
          handleResizeDrop={handleResizeDrop}
          scale={scale}
          selected={isSelected}
          inView={visible}
          zIndex={index}
        />
      )}
    </>
  );

  // todo: line-height will need matching values/style between front end and backend
  return (
    <CanvasMask x={position.x} y={position.y} width={dimensions.width || 1} height={dimensions.height || 1} clipId={id}>
      {contents}
    </CanvasMask>
  );
}

export default CanvasHtmlPlayer;
