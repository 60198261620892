import { Form } from 'react-bootstrap';

import SelectElement from '@components/atoms/SelectElement';

import camelCase from '@utils/text/camelCase';

function InputSelect({
  id,
  label,
  name,
  selected,
  values,
  disabled = false,
  showLabel = true,
  handleChange,
  tracking,
  helperText,
  Action,
}) {
  const inputId = id || camelCase(label);

  return (
    <Form.Group controlId={inputId}>
      {(label || Action) && (
        <div className="d-flex align-items-center justify-content-between">
          <div>{showLabel === true && <Form.Label>{label}</Form.Label>}</div>
          <div>{Action || null}</div>
        </div>
      )}
      <SelectElement
        name={name}
        disabled={disabled}
        options={values}
        selected={selected}
        handleEvent={handleChange}
        tracking={tracking}
      />

      {helperText && (
        <Form.Text className="helper-text text-muted">
          <span dangerouslySetInnerHTML={{ __html: helperText }} />
        </Form.Text>
      )}
    </Form.Group>
  );
}

export default InputSelect;
