import config from '@config';
import { atom } from 'recoil';

const stageState = atom({
  key: 'stage/state',
  default: config.headers.stage,
});

export const stageSelectionWarningState = atom({
  key: 'stage/warning',
  default: '',
});

export default stageState;
