import { memo } from 'react';

import { IconAudio } from '@assets/icons';

function TimelineClipAudio({ clipType }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconAudio size={12} />
        </span>
        <span className="clip__type">{clipType}</span>
      </div>
    </div>
  );
}

export default memo(TimelineClipAudio);
