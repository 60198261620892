import { atom, atomFamily, selector, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { assetsFamily, clipsAssetsFamily } from '@store/atoms/AssetsState';
import { clipTypesFamily } from '@store/atoms/ClipState';
import { hydrateOutputCallback, resetOutputCallback } from '@store/studio/Output';

import determineAssetType from '@utils/editor/determineAssetType';
import { setHTMLAssetDimensions } from '@utils/editor/setHTMLAssetDimensions';
import { getFont } from '@utils/fonts';
import { getReplacedData } from '@utils/merge';
import { reorganiseTracks } from '@utils/tracks';

const DEFAULT_BACKGROUND_COLOR = '#000000';
const DEFAULT_CACHE_STATE = true;

export const templateReadyState = atom({
  key: 'edit/template/ready',
  default: false,
});

export const viewState = atom({
  key: 'edit/view',
  default: 'gui',
});

export const mergeFamily = atomFamily({
  key: 'edit/merge',
  default: [],
});

export const mergeIdsState = atom({
  key: 'edit/merge/ids',
  default: [],
});

export const hasMergeFieldsSelector = selector({
  key: 'edit/merge/hasMergeFieldsSelector',
  get: ({ get }) => {
    const mergeIds = get(mergeIdsState);
    const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));
    const mergeFieldsWithKey = mergeFields.filter((mergeField) => mergeField.find);
    return mergeFieldsWithKey.length > 0;
  },
});

export const callbackState = atom({
  key: 'edit/callback',
  default: {},
});

export const diskState = atom({
  key: 'edit/disk',
  default: null,
});

export const soundtrackState = atom({
  key: 'edit/timeline/soundtrack',
  default: null,
});

export const backgroundState = atom({
  key: 'edit/timeline/background',
  default: DEFAULT_BACKGROUND_COLOR,
});

export const fontIdsState = atom({
  key: 'edit/timeline/font/ids',
  default: [],
});

export const fontsFamily = atomFamily({
  key: 'edit/timeline/font',
  default: {},
});

export const cacheState = atom({
  key: 'edit/timeline/cache',
  default: DEFAULT_CACHE_STATE,
});

export const trackIdsState = atom({
  key: 'edit/timeline/track/ids',
  default: [],
});

export const clipIdsState = atom({
  key: 'edit/timeline/clip/ids',
  default: [],
});

export const assetIdsState = atom({
  key: 'edit/timeline/asset/ids',
  default: [],
});

export const clipsFamily = atomFamily({
  key: 'edit/timeline/track/clip',
  default: [],
});

export const clipErrorsFamily = atomFamily({
  key: 'edit/timeline/track/clip/errors',
  default: [],
});

export const overridesFamily = atomFamily({
  key: 'edit/timeline/track/clip/overrides',
  default: {},
});

export const clipsTracksFamily = atomFamily({
  key: 'edit/timeline/clip/track',
  default: [],
});

export const isValidJson = atom({
  key: 'isValidJson',
  default: true,
});

export const editJson = atom({
  key: 'editJson',
  default: null,
});

export const trackVisibleState = atomFamily({
  key: 'edit/timeline/track/visible',
  default: true,
});

export const useGetUpdatedTemplate = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        snapshot.getPromise(editJson),
    []
  );
};

export const useAddFontState = () => {
  return useRecoilCallback(
    ({ set }) =>
      () => {
        const id = uuid();
        set(fontIdsState, (currentState) => {
          return [...currentState, id];
        });
        set(fontsFamily(id), {});
        return id;
      },
    []
  );
};

export const useUpdateFontState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (id, src) => {
        const currentFont = snapshot.getLoadable(fontsFamily(id)).contents;
        if (currentFont.src === src) {
          return [currentFont, null];
        }
        const [updatedFont, error] = await getFont({ src });
        if (error) {
          return [undefined, error];
        }
        set(fontsFamily(id), updatedFont);
        return [updatedFont, undefined];
      },
    []
  );
};

export const useDeleteFontState = () => {
  return useRecoilCallback(
    ({ set, reset }) =>
      (id) => {
        set(fontIdsState, (currentState) => currentState.filter((fontId) => fontId !== id));
        reset(fontsFamily(id));
      },
    []
  );
};

export const useHydrateStoreFromTemplate = () => {
  const hydrateStore = useRecoilCallback((callbackArgs) => {
    const { set, reset, snapshot } = callbackArgs;
    const resetOutput = resetOutputCallback(callbackArgs);
    const hydrateOutput = hydrateOutputCallback(callbackArgs);

    return async (json) => {
      const { output, merge, callback, disk, timeline } = json || {};
      const { soundtrack, tracks, fonts } = timeline || {};

      const replacements = (merge || []).reduce((acc, { find, replace }) => {
        acc[find] = replace;
        return acc;
      }, {});

      const currentClipIds = snapshot.getLoadable(clipIdsState).contents;
      currentClipIds.forEach((clipId) => {
        reset(clipsFamily(clipId));
        reset(clipsAssetsFamily(clipId));
        reset(overridesFamily(clipId));
      });

      const currentAssetIds = snapshot.getLoadable(assetIdsState).contents;
      currentAssetIds.forEach((assetId) => {
        reset(assetsFamily(assetId));
        reset(overridesFamily(assetId));
      });

      const currentFontIds = snapshot.getLoadable(fontIdsState).contents;
      currentFontIds.forEach((fontId) => reset(fontsFamily(fontId)));

      const currentMergeIdsState = snapshot.getLoadable(mergeIdsState).contents;
      currentMergeIdsState.forEach((mergeId) => reset(mergeFamily(mergeId)));

      reset(trackIdsState);
      reset(clipIdsState);
      reset(assetIdsState);
      reset(fontIdsState);
      reset(mergeIdsState);

      reset(callbackState);
      reset(soundtrackState);

      reset(overridesFamily('callback'));
      reset(overridesFamily('soundtrack'));

      resetOutput();

      set(diskState, disk || null);

      set(backgroundState, timeline?.background || DEFAULT_BACKGROUND_COLOR);

      set(cacheState, () => (timeline?.cache === false ? timeline.cache : DEFAULT_CACHE_STATE));

      const { merged: mergedCallback, overrides: callbackOverrides } = getReplacedData({ src: callback }, replacements);
      set(overridesFamily('callback'), callbackOverrides);
      set(callbackState, mergedCallback);

      const { merged: mergedSoundtrack, overrides: soundtrackOverrides } = getReplacedData(soundtrack, replacements);
      set(overridesFamily('soundtrack'), soundtrackOverrides);
      set(soundtrackState, mergedSoundtrack);

      (merge || []).forEach(async ({ find, replace }, index) => {
        const mergeId = uuid();
        set(mergeIdsState, (currentState) => {
          return !currentState[index] ? currentState.concat([mergeId]) : currentState;
        });
        const replaceType = typeof replace;
        const type = replaceType === 'undefined' ? 'string' : replaceType;
        const newMerge = { id: mergeId, find, replace, meta: { type } };
        set(mergeFamily(mergeId), newMerge);
      });

      const organisedTracks = reorganiseTracks(tracks);

      (organisedTracks || []).forEach((track, trackIndex) => {
        const trackId = uuid();
        set(trackIdsState, (currentState) => {
          return !currentState[trackIndex] ? currentState.concat([trackId]) : currentState;
        });

        track.forEach((clip) => {
          const clipId = uuid();
          set(clipIdsState, (currentState, index) => {
            return clipIdsState[index] ? currentState : currentState.concat([clipId]);
          });

          const { asset, ...clipWithoutAsset } = clip;
          if (asset) {
            const assetId = uuid();
            set(assetIdsState, (currentState) => [...currentState, assetId]);

            // todo: move setHTMLAssetDimensions to a transform file
            const rawAsset = { id: assetId, ...setHTMLAssetDimensions(asset, output) };
            const { merged: mergedAsset, overrides: assetOverrides } = getReplacedData(rawAsset, replacements);

            set(assetsFamily(assetId), mergedAsset);
            set(clipsAssetsFamily(clipId), assetId);
            set(clipTypesFamily(clipId), determineAssetType(mergedAsset));
            set(overridesFamily(assetId), assetOverrides);
          }

          const rawClip = { id: clipId, ...clipWithoutAsset };
          const { merged: mergedClip, overrides: clipOverrides } = getReplacedData(rawClip, replacements);

          set(clipsTracksFamily(clipId), { clipId, trackId, trackIndex });
          set(clipsFamily(clipId), mergedClip);
          set(overridesFamily(clipId), clipOverrides);
        });
      });

      (fonts || []).forEach(async (font, fontIndex) => {
        const fontId = uuid();
        set(fontIdsState, (currentState) => {
          return !currentState[fontIndex] ? currentState.concat([fontId]) : currentState;
        });
        const [newFont, error] = await getFont(font);
        if (error) {
          console.error(error);
        }
        set(fontsFamily(fontId), { id: fontId, ...font, ...newFont });
      });

      hydrateOutput(output);
      set(templateReadyState, true);
    };
  }, []);

  return hydrateStore;
};
