import { getHeaders } from '@api/utils';

const requestInterceptors = [
  (config) => {
    const withStage = !/workflow.shotstack|integrate.shotstack|onboard.shotstack/.test(config.baseURL);
    const withUserAgent = !/integrate.shotstack|onboard.shotstack/.test(config.baseURL);
    return {
      ...config,
      headers: {
        ...config.headers,
        ...getHeaders({ withStage, withUserAgent }),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  },
];

export default requestInterceptors;
