import { Sprite } from '@inlet/react-pixi';
import { useRef, useState } from 'react';

import drawHandle from '@assets/img/draw-handle.svg';

const setCursorType = (position) => {
  let cursorType;
  switch (position) {
    case 'top':
    case 'bottom':
      cursorType = 'ns-resize';
      break;
    case 'topRight':
    case 'bottomLeft':
      cursorType = 'nesw-resize';
      break;
    case 'right':
    case 'left':
      cursorType = 'ew-resize';
      break;
    default:
      cursorType = 'nwse-resize';
  }

  return cursorType;
};

function FrameHandle(props) {
  const {
    coordinates,
    handleDrag,
    handleDrop,
    handleGrab,
    inView,
    isAdjusting,
    position,
    setIsAdjusting,
    setVisible,
    visible,
    zIndex,
  } = props;

  const [isSelected, setIsSelected] = useState(false);

  const cursorType = setCursorType(position);

  const { x, y } = coordinates;
  const spriteRef = useRef();

  const handlePointerOver = () => {
    setVisible(true);
  };

  const handlePointerOut = () => {
    if (!isAdjusting) {
      setVisible(false);
    }
  };

  const handleOnDrag = (event) => {
    if (isAdjusting && isSelected) {
      handleDrag(event, position);
    }
  };

  const handleOnDrop = (event) => {
    handleDrop(event);
    setIsAdjusting(false);
    setIsSelected(false);
  };

  const handleOnGrab = (event) => {
    handleGrab(event);
    setIsAdjusting(true);
    setIsSelected(true);
  };

  return (
    <Sprite
      alpha={visible ? 1 : 0.00001}
      ref={spriteRef}
      image={drawHandle}
      scale={{ x: 1, y: 1 }}
      anchor={0.5}
      x={x}
      y={y}
      interactive
      cursor={cursorType}
      pointerdown={handleOnGrab}
      pointermove={handleOnDrag}
      pointerover={handlePointerOver}
      pointerout={handlePointerOut}
      pointerup={handleOnDrop}
      pointerupoutside={handleOnDrop}
      visible={inView}
      zIndex={zIndex}
    />
  );
}

export default FrameHandle;
