import { IconPause, IconPlay, IconStop } from '@assets/icons';
import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';
import '@css/TimelineControls.css';

function TimelineControls() {
  const { isPlayable, isPlaying, handlePlayPause, handleStop } = useTimelinePlaybackContext();

  if (!isPlayable) {
    return null;
  }

  return (
    <div className='controls'>
      <button type='button' className='controls__playpause' onClick={handlePlayPause}>
        {!isPlaying ? (
          <IconPlay className='controls__iconstroke' size={36} />
        ) : (
          <IconPause className='controls__iconstroke' size={36} />
        )}
      </button>

      <button type='button' className='controls__stop' onClick={handleStop}>
        <IconStop className='controls__iconstroke' size={36} />
      </button>
    </div>
  );
}

export default TimelineControls;
