import { Button, Form, InputGroup } from 'react-bootstrap';

import { IconTrash } from '@assets/icons';

import InputTextElement from '@components/atoms/InputTextElement';

import camelCase from '@utils/text/camelCase';

function InputText({
  id,
  label,
  defaultValue,
  name,
  value,
  placeholder,
  helperText,
  handleChange,
  handleDelete,
  handleBlur,
  tracking,
  disabled = false,
  isInvalid = false,
  showLabel = true,
  maxLength,
  Action,
}) {
  const inputId = id || camelCase(label);
  return (
    <Form.Group controlId={inputId}>
      {(label || Action) && (
        <div className="d-flex align-items-center justify-content-between">
          <div>{showLabel === true && <Form.Label>{label}</Form.Label>}</div>
          <div>{Action || null}</div>
        </div>
      )}
      <InputGroup>
        <InputTextElement
          inputType="text"
          disabled={disabled}
          defaultValue={defaultValue}
          handleChange={handleChange}
          handleOnBlur={handleBlur}
          tracking={tracking}
          name={name}
          value={value}
          placeholder={placeholder}
          isInvalid={isInvalid}
          maxLength={maxLength}
        />
        {handleDelete && (
          <Button variant="light" onClick={handleDelete}>
            <IconTrash size={16} />
          </Button>
        )}
      </InputGroup>
      {helperText && (
        <Form.Text className="helper-text text-muted">
          <span dangerouslySetInnerHTML={{ __html: helperText }} />
        </Form.Text>
      )}
    </Form.Group>
  );
}

export default InputText;
