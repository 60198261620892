import Editor from '@pages/Editor';
import Error from '@pages/Error';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { Routes as RDRoutes, Route } from 'react-router-dom';

const SentryRoutes = withSentryReactRouterV6Routing(RDRoutes);

function Routes() {
  return (
    <SentryRoutes>
      <Route path='/error' element={<Error />} />
      <Route path='/' element={<Editor />} />
    </SentryRoutes>
  );
}

export default Routes;
