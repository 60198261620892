import { Graphics } from '@inlet/react-pixi';
import { useCallback, useEffect, useRef, useState } from 'react';

function FrameOutline(props) {
  const {
    bounds,
    width,
    handleGrab,
    handleDrag,
    handleDrop,
    height,
    inView,
    isAdjusting,
    setVisible,
    visible,
    zIndex,
  } = props;
  const graphicsRef = useRef();
  const [lineSize, setLineSize] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = useCallback(
    (event) => {
      setIsDragging(true);
      handleGrab(event);
    },
    [setIsDragging, handleGrab]
  );

  const handlePointerOut = useCallback(() => {
    if (!isAdjusting) {
      setVisible(false);
    }
  }, [setVisible, isAdjusting]);

  const handlePointerOver = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handlePointerUp = useCallback(
    (event) => {
      setIsDragging(false);
      handleDrop(event);
    },
    [setIsDragging, handleDrop]
  );

  useEffect(() => {
    setLineSize(visible ? 3 : 0);
  }, [visible, setLineSize]);

  const draw = useCallback(
    (graphic) => {
      graphic.clear();
      graphic.beginFill(0x0396ff, 0.00001);
      graphic.lineStyle(lineSize, 0x0396ff);
      graphic.drawRect(bounds.topLeft.x, bounds.topLeft.y, width, height);
    },
    [lineSize, bounds, width, height]
  );

  return (
    <Graphics
      ref={graphicsRef}
      draw={draw}
      interactive
      moveWhenInside
      pointerdown={handlePointerDown}
      pointermove={handleDrag}
      pointerout={handlePointerOut}
      pointerover={handlePointerOver}
      pointerup={handlePointerUp}
      pointerupoutside={handleDrop}
      cursor={isDragging ? 'grabbing' : 'grab'}
      visible={inView}
      zIndex={zIndex}
    />
  );
}

export default FrameOutline;
