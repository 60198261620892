import { useRecoilValue } from 'recoil';

import CanvasAudio from '@feature/studio/canvas/CanvasAudio';
import CanvasHtml from '@feature/studio/canvas/CanvasHtml';
import CanvasImage from '@feature/studio/canvas/CanvasImage';
import CanvasVideo from '@feature/studio/canvas/CanvasVideo';

import { clipAssetState } from '@store/selectors/EditSelectors';

const CanvasComponentMap = {
  audio: CanvasAudio,
  html: CanvasHtml,
  image: CanvasImage,
  video: CanvasVideo,
  overlay: CanvasVideo,
};

function CanvasClip({ id, index }) {
  const asset = useRecoilValue(clipAssetState(id));
  const CanvasComponent = CanvasComponentMap[asset?.type];

  if (!CanvasComponent) {
    return null;
  }

  return <CanvasComponent key={id} id={id} index={index} />;
}

export default CanvasClip;
