const baseConfig = {
  development: {
    stage: 'dev',
    proxy: 'whptxx64dert6ffkbi2v3gdnta0btbad',
    stages: {
      integration: [
        ['dev', 'Development'],
        ['v1', 'Production'],
      ],
      serve: [
        ['dev', 'Development'],
        ['v1', 'Production'],
      ],
      edit: [
        ['dev', 'Development'],
        ['stage', 'Sandbox'],
        ['v1', 'Production'],
      ],
    },
    auth0Domain: 'shotstack-dev.au.auth0.com',
    auth0ClientId: 'n9o1fsWdF3ZX75McErMNUQkL15gkELn5',
    stripeKey: 'pk_test_Pb7Aipr6LMmP8Z90TWXhJyPv007nxaB4sE',
    segmentWriteKey: 'aJimgA6CIJSzsgmSC7uIfNJxcPRvK7MK',
    gtmId: 'GTM-TMRXW9S',
    hotjarId: 3327150,
    typeformId: 'ITY31kB5',
    algoliaSearchIndex: 'dev_CONTENT',
    intercom: {
      appId: 'kve2maxg',
      firstVideoTutorial: '8115272',
      mergeVideoTutorial: '8118574',
      apiVideoTutorial: '8118575',
    },
  },
  production: {
    stage: 'v1',
    proxy: 'kt7qcky26qjvkulsxfi7usitxi0acpyx',
    stages: {
      integration: [
        ['stage', 'Sandbox'],
        ['v1', 'Production'],
      ],
      serve: [
        ['dev', 'Development'],
        ['v1', 'Production'],
      ],
      edit: [
        ['stage', 'Sandbox'],
        ['v1', 'Production'],
      ],
    },
    auth0Domain: 'auth.shotstack.io',
    auth0ClientId: 'oRFYPQsCP5OvNaRByoB7nUIgJq5HQXG6',
    stripeKey: 'pk_live_3WLjEh246eA6yhjR4xK6UVeH00Q0VFCu5l',
    segmentWriteKey: 'R6tpTnuu5wGYGn62HCnDzXrzjkLaOdNJ',
    gtmId: 'GTM-55KWVSM',
    hotjarId: 3322612,
    typeformId: 'V3OmOwD6',
    algoliaSearchIndex: 'prod_CONTENT',
    intercom: {
      appId: 'du1bntg0',
      firstVideoTutorial: '8009759',
      mergeVideoTutorial: '8010130',
      apiVideoTutorial: '8010146',
    },
  },
};

const getConfig = (env) => {
  const config = baseConfig[env] || baseConfig.development;

  return {
    env,
    headers: {
      stage: config.stage,
      userAgent: 'ShotstackEditor/0.1.0',
    },
    onboarding: {
      url: `https://onboard.shotstack.io/${config.stage}/`,
    },
    api: {
      url: `https://data-api.shotstack.io/${config.stage}/dashboard/`,
    },
    proxy: {
      url: `https://${config.proxy}.lambda-url.ap-southeast-2.on.aws`,
    },
    integration: {
      url: `https://integrate.shotstack.io/${config.stage}/`,
      stages: config.stages.integration.map(([stage, name]) => ({ stage, name })),
    },
    edit: {
      url: `https://api.shotstack.io/${config.stage}/dashboard/`,
      stages: config.stages.edit.map(([stage, name]) => ({ stage, name })),
    },
    serve: {
      url: `https://api.shotstack.io/serve/${config.stage}/dashboard/`,
      stages: config.stages.serve.map(([stage, name]) => ({ stage, name })),
    },
    ingest: {
      url: `https://api.shotstack.io/ingest/${config.stage}/dashboard/`,
    },
    workflow: {
      url: `https://workflow.shotstack.io/`,
    },
    auth0: {
      domain: config.auth0Domain,
      clientId: config.auth0ClientId,
      audience: `https://data-api.shotstack.io/${config.stage}/dashboard`,
    },
    stripe: {
      publishableKey: config.stripeKey,
    },
    analytics: {
      gtm: {
        id: config.gtmId,
      },
      hotjar: {
        id: config.hotjarId,
        version: 6,
      },
      segment: {
        writeKey: config.segmentWriteKey,
      },
    },
    typeform: {
      id: config.typeformId,
    },
    algolia: {
      appId: 'KIS5HOXZ9Y',
      apiKey: 'b55b6dccc6732dd0653a3ddd728a09dd',
      index: config.algoliaSearchIndex,
    },
    intercom: {
      appId: config.intercom.appId,
      firstVideoTutorial: config.intercom.firstVideoTutorial,
      mergeVideoTutorial: config.intercom.mergeVideoTutorial,
      apiVideoTutorial: config.intercom.apiVideoTutorial,
    },
  };
};

const env = process.env.REACT_APP_STAGE;

export default {
  isDev: env === 'development',
  ...getConfig(env),
};
