import { memo, useRef } from 'react';
import { GripHorizontal as IconDragHorizontal } from 'react-bootstrap-icons';
import { useResetRecoilState } from 'recoil';

import TimelineHeader from '@feature/studio/timeline/TimelineHeader';
import TimelinePlayheadLine from '@feature/studio/timeline/TimelinePlayheadLine';
import TimelineScale from '@feature/studio/timeline/TimelineScale';
import TimelineTracks from '@feature/studio/timeline/TimelineTracks';

import { activeClipState } from '@store/atoms/ClipState';

function WorkspaceTimeline({ templateLoading, height, getRef }) {
  const tracksElmRef = useRef(null);
  const resetActiveClip = useResetRecoilState(activeClipState);

  const handleTrackClick = (e) => {
    const { target } = e || {};
    const isClipClick = Array.from(target?.classList || []).includes('clip');
    if (!isClipClick) {
      resetActiveClip();
    }
  };
  return (
    <div ref={getRef} style={{ height }} className={`workspace__timeline`}>
      {/* <div className="timeline__resize-handle">
        <div className="timeline__resize-notch">
          <IconDragHorizontal />
        </div>
      </div> */}
      {/* <div className="workspace__toolbar">
        <TimelineScale />
      </div> */}
      <div className="workspace__tracks" ref={tracksElmRef}>
        {/* <TimelineHeader /> */}
        <div id="tracks-container" className="tracks-container" onClick={handleTrackClick}>
          <TimelinePlayheadLine />
          <TimelineTracks tracksElmRef={tracksElmRef} />
        </div>
      </div>
    </div>
  );
}

export default memo(WorkspaceTimeline);
