import { memo } from 'react';

import { IconMask } from '@assets/icons';

function TimelineClipMask({ clipType }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconMask size={12} />
        </span>
        <span className="clip__type">{clipType}</span>
      </div>
    </div>
  );
}

export default memo(TimelineClipMask);
