import { selectorFamily } from 'recoil';

import { clipIdsState, clipsFamily, clipsTracksFamily } from '@store/atoms/EditState';
import { clipAssetState, clipVisibilityState } from '@store/selectors/EditSelectors';

import { ASSET_TYPES_MASK } from '@constants/AssetTypes';

export const clipMaskState = selectorFamily({
  key: 'clipMaskState',
  get:
    (clipId) =>
    ({ get }) => {
      const clips = get(clipIdsState).map((id) => ({
        /** @ts-ignore @type {{ start: number; length: number; }} */
        info: get(clipsFamily(id)),
        /** @ts-ignore @type {{ trackIndex: number; clipId: number; }} */
        track: get(clipsTracksFamily(id)),
        /** @ts-ignore @type {{ src: string; type: string; trim?: number; volume?: number; }} */
        asset: get(clipAssetState(id)),
        visible: get(clipVisibilityState(id)),
      }));

      const clipTrackIdx = clips.find((clip) => clip.track.clipId === clipId)?.track?.trackIndex;
      const activeMask = clips.find((clip) => {
        const isMask = ASSET_TYPES_MASK.includes(clip.asset?.type);
        const isSameTrack = clip.track?.trackIndex === clipTrackIdx;
        const isClipVisible = clip.visible;
        return isMask && isSameTrack && isClipVisible;
      });

      return activeMask;
    },
});
