import { getAssetMetaData } from '@api/services/asset';
import { transformAssetIncoming } from '@api/transform/utils/assets';

import { RENDITION_WIDTH } from '@constants/Uploads';

export const transformClip = async (clip, mergeData) => {
  const { asset } = clip;
  const { attributes } = await getAssetMetaData(asset, mergeData);
  const transformedAsset = await transformAssetIncoming(asset);
  const originalScale = clip.scale || 1;

  let proxyScaleMultiplier = originalScale;
  if (transformedAsset.meta?.proxied && attributes.width) {
    proxyScaleMultiplier = attributes.width / RENDITION_WIDTH;
  }

  const newAsset = {
    ...transformedAsset,
    meta: {
      ...(transformedAsset?.meta || {}),
      ...(attributes || {}),
      proxyScaleMultiplier,
    },
  };

  return { ...clip, asset: newAsset, meta: clip.meta || {} };
};
