import axios from 'axios';
import { omit, pick } from 'lodash';

import { convertCSSToJSON, convertHTMLToJSON, convertJSONToHTML } from '@utils/editor/htmlPropertyConversion';
import { isNumber } from '@utils/isNumber';

const overlaySourceRegex = /\/([^/]+)\/source\.(mov|qt)$/;

const checkFileExists = async (url) => {
  try {
    const { status } = await axios.head(url);
    return status === 200;
  } catch (error) {
    console.warn(`${url} not accessible`, error.toJSON());
    return false;
  }
};

const getFirstExistingOverlayUrl = async (urls) => {
  const results = await Promise.all(urls.map((url) => checkFileExists(url)));
  const found = results.indexOf(true);
  return urls[found] || null;
};

const transformOverlay = async (asset) => {
  const newAsset = { ...asset };
  const overlayRenditionSrc = asset.src.replace('-sources', '-renditions');
  const overlaySrcDeprecated = overlayRenditionSrc.replace(overlaySourceRegex, '/$1/overlay.webm');
  const overlaySrcNew = overlayRenditionSrc.replace(overlaySourceRegex, '/$1/shotstack-overlay.webm');
  const overlaySrc = await getFirstExistingOverlayUrl([overlaySrcNew, overlaySrcDeprecated]);
  const proxied = asset.src.includes('ingest-api') ? Boolean(overlaySrc) : false;

  newAsset.type = 'overlay';
  newAsset.meta = {
    ...(asset.meta || {}),
    type: 'video',
    source: asset.src,
    proxied,
  };

  if (proxied) {
    newAsset.src = overlaySrc;
  }
  return newAsset;
};

const transformVideo = async (asset) => {
  const newAsset = { ...asset };
  const proxySrc = asset.src.replace('-sources', '-renditions').replace(/\/source\.\w+$/, '/shotstack-proxy.mp4');
  const proxied = asset.src.includes('ingest-api') ? await checkFileExists(proxySrc) : false;

  newAsset.meta = {
    ...(asset.meta || {}),
    source: asset.src,
    proxied,
  };

  if (proxied) {
    newAsset.src = proxySrc;
  }
  return newAsset;
};

const transformVideoIncoming = async (asset) => {
  if (!asset || !asset.src) {
    return asset;
  }
  const isOverlayVideo = asset.src.match(overlaySourceRegex)?.length;
  if (isOverlayVideo) {
    return transformOverlay(asset);
  }
  return transformVideo(asset);
};

const transformLumaIncoming = async (asset) => {
  const newAsset = { ...asset, type: 'mask' };
  const proxySrc = asset.src.replace('-sources', '-renditions').replace(/\/source\.\w+$/, '/shotstack-proxy.webm');
  const proxied = asset.src.includes('ingest-api') ? await checkFileExists(proxySrc) : false;

  newAsset.meta = {
    ...(asset.meta || {}),
    source: asset.src,
    type: 'luma',
    proxied,
  };

  if (proxied) {
    newAsset.src = proxySrc;
  }
  return newAsset;
};

const transformImageIncoming = async (asset) => {
  const newAsset = { ...asset };
  const proxySrc = asset.src.replace('-sources', '-renditions').replace(/\/source\.\w+$/, '/shotstack-proxy.png');
  const proxied = asset.src.includes('ingest-api') ? await checkFileExists(proxySrc) : false;

  newAsset.meta = {
    ...(asset.meta || {}),
    source: asset.src,
    proxied,
  };

  if (proxied) {
    newAsset.src = proxySrc;
  }
  return newAsset;
};

const cssTransformMap = {
  fontSize: (value) => {
    try {
      const fontSize = parseInt(value, 10);
      if (!isNumber(fontSize)) {
        return value;
      }
      return String(fontSize);
    } catch {
      return value;
    }
  },
  fontFamily: (value) => {
    return value.replace(/'|\"/g, '');
  },
  default: (value) => value,
};

const transformHTMLIncoming = (asset) => {
  const { css, html, ...newAsset } = asset || {};

  if (!html.includes('data-html-type="text"')) {
    return { ...asset, meta: { unsupported: true } };
  }

  try {
    const htmlProps = convertHTMLToJSON(html);
    const cssProps = Object.entries(convertCSSToJSON(css)).reduce((acc, [key, value]) => {
      const transform = cssTransformMap[key] || cssTransformMap.default;
      acc[key] = transform(value);
      return acc;
    }, {});
    return { ...newAsset, ...htmlProps, ...cssProps };
  } catch (e) {
    console.error('transformHTMLIncoming', e);
    return asset;
  }
};

const transformAssetIncomingMap = {
  image: transformImageIncoming,
  video: transformVideoIncoming,
  luma: transformLumaIncoming,
  mask: transformLumaIncoming,
  html: transformHTMLIncoming,
  default: (asset) => asset,
};

export const transformAssetIncoming = (asset) => {
  const transformAsset = transformAssetIncomingMap[asset?.type] || transformAssetIncomingMap.default;
  const transformedAsset = transformAsset(asset);
  return transformedAsset;
};

const transformHTMLOutgoing = (asset) => {
  if (asset?.meta?.unsupported) {
    return asset;
  }

  const baseHtmlAssetProps = pick(asset, ['type', 'width', 'height', 'background', 'position']);
  const htmlCssProps = omit(asset, ['id', 'type', 'width', 'height', 'background', 'position', 'meta', 'text']);

  const { html, css } = convertJSONToHTML({ ...htmlCssProps, text: asset?.meta?.text });

  return { ...baseHtmlAssetProps, html, css };
};

export const transformAssetOutgoing = (asset) => {
  let newAsset = { ...asset };
  if (['html', 'title'].includes(asset?.type)) {
    if (asset.type === 'html') {
      newAsset = transformHTMLOutgoing(asset);
    } else {
      newAsset.text = asset?.meta?.text;
    }
  }

  if (asset?.meta?.proxied) {
    newAsset.src = asset?.meta?.source;
  }

  if (asset?.type === 'mask') {
    newAsset.type = 'luma';
  }

  if (asset?.type === 'overlay') {
    newAsset.type = 'video';
  }

  return newAsset;
};
