// https://react-icons.github.io/react-icons/
import { BiSolidErrorAlt } from 'react-icons/bi';
import {
  BsArrowCounterclockwise,
  BsArrowLeftRight,
  BsArrowReturnRight,
  BsArrowRight,
  BsBoxArrowUpRight,
  BsBoxes,
  BsBraces,
  BsBracesAsterisk,
  BsCameraVideo,
  BsCheck,
  BsCheckCircleFill,
  BsCheckLg,
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronUp,
  BsClipboard,
  BsClipboardCheck,
  BsCode,
  BsCodeSlash,
  BsCollectionPlay,
  BsDiamond,
  BsDiamondFill,
  BsDoorOpenFill,
  BsExclamationCircleFill,
  BsExclamationTriangle,
  BsExclamationTriangleFill,
  BsEye,
  BsEyeSlashFill,
  BsFiles,
  BsFilm,
  BsFonts,
  BsGearFill,
  BsGripVertical,
  BsHr,
  BsImage,
  BsInfoSquare,
  BsLayersHalf,
  BsLightningChargeFill,
  BsLink45Deg,
  BsMask,
  BsMusicNoteBeamed,
  BsNutFill,
  BsPauseFill,
  BsPencilSquare,
  BsPlayFill,
  BsPlusCircle,
  BsQuestionCircle,
  BsServer,
  BsSignMergeLeftFill,
  BsSlashCircle,
  BsStopFill,
  BsThreeDots,
  BsTrash2,
  BsTrash2Fill,
  BsX,
  BsZoomIn,
} from 'react-icons/bs';
import { HiMiniBolt } from 'react-icons/hi2';
import { IoIosWarning } from 'react-icons/io';
import { LuClock3, LuCoins, LuFileSpreadsheet, LuWorkflow } from 'react-icons/lu';
import { MdOutlinePermMedia } from 'react-icons/md';
import {
  PiAlignBottomFill,
  PiAlignCenterVerticalFill,
  PiAlignTopFill,
  PiTextAlignCenterBold,
  PiTextAlignLeftBold,
  PiTextAlignRightBold,
} from 'react-icons/pi';

export const IconAlignBottom = PiAlignBottomFill;
export const IconAlignTop = PiAlignTopFill;
export const IconAlignCenterVertically = PiAlignCenterVerticalFill;
export const IconTextAlignCenter = PiTextAlignCenterBold;
export const IconTextAlignLeft = PiTextAlignLeftBold;
export const IconTextAlignRight = PiTextAlignRightBold;
export const IconMusicNoteBeamed = BsMusicNoteBeamed;
export const IconFonts = BsFonts;
export const IconCode = BsCode;
export const IconImage = BsImage;
export const IconMask = BsMask;
export const IconOverlay = BsLayersHalf;
export const IconCameraVideo = BsCameraVideo;
export const IconChevronLeft = BsChevronLeft;
export const IconLink = BsLink45Deg;
export const IconTrash = BsTrash2Fill;
export const IconQuestionCircle = BsQuestionCircle;
export const IconBraces = BsBraces;
export const IconCollectionPlay = BsCollectionPlay;
export const IconGear = BsGearFill;
export const IconCodeSlash = BsCodeSlash;
export const IconCheckCircle = BsCheckCircleFill;
export const IconExclamationCircle = BsExclamationCircleFill;
export const IconSlashCircle = BsSlashCircle;
export const IconMerge = BsSignMergeLeftFill;
export const IconOverrideItem = BsDiamond;
export const IconOverrideItemSelected = BsDiamondFill;
export const IconOverride = BsBraces;
export const IconOverriden = BsBracesAsterisk;
export const IconExternal = BsBoxArrowUpRight;
export const IconClipboard = BsClipboard;
export const IconClipboardCheck = BsClipboardCheck;
export const IconReset = BsArrowCounterclockwise;
export const IconServer = BsServer;
export const IconCSV = LuFileSpreadsheet;
export const IconAudio = BsMusicNoteBeamed;
export const IconChevronDown = BsChevronDown;
export const IconChevronUp = BsChevronUp;
export const IconVideo = BsFilm;
export const IconHr = BsHr;
export const IconExclamationTriangle = BsExclamationTriangle;
export const IconExclamationTriangleFill = BsExclamationTriangleFill;
export const IconPlusCircle = BsPlusCircle;
export const IconFiles = BsFiles;
export const IconPencilSquare = BsPencilSquare;
export const IconDuration = LuClock3;
export const IconStartEnd = BsArrowLeftRight;
export const IconMore = BsThreeDots;
export const IconPause = BsPauseFill;
export const IconPlay = BsPlayFill;
export const IconStop = BsStopFill;
export const IconZoomIn = BsZoomIn;
export const IconDrag = BsGripVertical;
export const IconInvisible = BsEyeSlashFill;
export const IconText = BsFonts;
export const IconVisible = BsEye;
export const IconProperties = BsBoxes;
export const IconCheck = BsCheck;
export const IconCheckLg = BsCheckLg;
export const IconArrowRight = BsArrowRight;
export const IconClose = BsX;
export const IconDelete = BsTrash2;
export const IconChevronRight = BsChevronRight;
export const IconInfo = BsInfoSquare;
export const IconArrowReturnRight = BsArrowReturnRight;
export const IconNutBolt = BsNutFill;
export const IconDoorOpen = BsDoorOpenFill;
export const IconLightningCharge = BsLightningChargeFill;
export const IconWorkflow = LuWorkflow;
export const IconWarning = IoIosWarning;
export const IconMedia = MdOutlinePermMedia;
export const IconError = BiSolidErrorAlt;
export const IconCoins = LuCoins;
export const IconBolt = HiMiniBolt;
