import { atom, selector } from 'recoil';

import onboardingService from '@api/services/onboarding';

import stageState from '@store/atoms/StageState';

export const authToken = atom({
  key: 'auth/token',
  default: null,
});

export const authenticated = atom({
  key: 'auth/state',
  default: false,
});

export const authenticatedUser = atom({
  key: 'auth/user',
  default: null,
});

export const authReady = selector({
  key: 'auth/ready',
  default: false,
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const token = get(authToken);
    const stage = get(stageState);
    const ready = Boolean(token && stage);

    if (!ready) {
      return new Promise(() => {}); // Returns an unresolved promise if not ready
    }
    return ready;
  },
});

export const authKeys = selector({
  key: 'auth/keys',
  get: async ({ get }) => {
    await get(authReady);
    const keys = await onboardingService.getKeys();
    return keys;
  },
});

export const authPlan = selector({
  key: 'auth/plan',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    await get(authReady);
    const authUser = get(authenticatedUser);

    if (authUser?.plans?.v1) {
      return authUser.plans.v1;
    }

    return new Promise((resolve) => setTimeout(() => resolve('free'), 5000));
  },
});

export const userDetailsSelector = selector({
  key: 'auth/details/selector',
  get: async ({ get }) => {
    await get(authReady);
    const user = await onboardingService.getUser();
    return user;
  },
});
