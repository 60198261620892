import { Form, InputGroup } from 'react-bootstrap';

import InputNumberElement from '@components/atoms/InputNumberElement';

import camelCase from '@utils/text/camelCase';

function InputNumber({
  id,
  label,
  value,
  name,
  handleBlur,
  handleChange,
  placeholder,
  min,
  max,
  showLabel = true,
  step = 1,
  suffix,
  tracking,
  disabled = false,
  Action,
}) {
  const inputId = id || camelCase(label);
  return (
    <Form.Group controlId={inputId}>
      {(label || Action) && (
        <div className="d-flex align-items-center justify-content-between">
          <div>{showLabel === true && <Form.Label>{label}</Form.Label>}</div>
          <div>{Action || null}</div>
        </div>
      )}
      <InputGroup>
        <InputNumberElement
          name={name}
          value={value}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder={placeholder}
          min={min}
          max={max}
          step={step}
          tracking={tracking}
          disabled={disabled}
        />
        {suffix?.length > 0 && <InputGroup.Text className="suffix">{suffix}</InputGroup.Text>}
      </InputGroup>
    </Form.Group>
  );
}

export default InputNumber;
