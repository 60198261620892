import '@css/TimelineTrack.css';

import {
  IconAudio,
  IconDrag,
  IconImage,
  IconInvisible,
  IconMask,
  IconOverlay,
  IconText,
  IconTrash,
  IconVideo,
  IconVisible,
} from '@assets/icons';

import { STUDIO_TRACK_HIDE_ENABLED } from '@src/featureFlags';

import ButtonElement from '@components/atoms/ButtonElement';

const ICON_SIZE = 12;

const trackButtons = [
  {
    type: 'video',
    title: 'Add Video',
    IconComponent: IconVideo,
  },
  {
    type: 'image',
    title: 'Add Image',
    IconComponent: IconImage,
  },
  {
    type: 'text',
    title: 'Add Text',
    IconComponent: IconText,
  },
  {
    type: 'overlay',
    title: 'Add Overlay',
    IconComponent: IconOverlay,
  },
  {
    type: 'audio',
    title: 'Add Audio',
    IconComponent: IconAudio,
  },
  {
    type: 'mask',
    title: 'Add Mask',
    IconComponent: IconMask,
  },
];

function TimelineTrack({
  id,
  index,
  handleAddClip,
  handleDeleteTrack,
  handleToggleTrackVisible,
  isTrackVisible,
  tracking,
  getRef,
}) {
  return (
    <div ref={getRef} className="track" data-track-id={id} data-track-index={index}>
      {/* <div className="track__header">
        <div className="track__title">
          <div className="track__drag-handle">
            <IconDrag size={18} />
          </div>
          <div>
            <h3>{`Track ${index + 1}`}</h3>
          </div>
        </div>
        <div className="track__actions">
          <div className="track__buttons">
            {trackButtons.map(({ type, title, IconComponent }) => (
              <ButtonElement
                key={type}
                handleEvent={handleAddClip(type)}
                variant="link"
                type="button"
                tracking={{ name: title, ...tracking }}
                title={title}
              >
                <IconComponent size={ICON_SIZE} title={title} />
              </ButtonElement>
            ))}
          </div>
          <div>
            <div className="track__buttons">
              {STUDIO_TRACK_HIDE_ENABLED && (
                <ButtonElement
                  handleEvent={handleToggleTrackVisible}
                  variant="link"
                  type="button"
                  tracking={{ name: 'Toggle Track Visible', ...tracking }}
                  title="Toggle Track Visible"
                >
                  {isTrackVisible ? (
                    <IconVisible size={ICON_SIZE} title="Toggle Track Visible" />
                  ) : (
                    <IconInvisible size={ICON_SIZE} title="Toggle Track Visible" />
                  )}
                </ButtonElement>
              )}
              <ButtonElement
                handleEvent={handleDeleteTrack}
                variant="link"
                type="button"
                tracking={{ name: 'Delete Track', ...tracking }}
                title="Delete Track"
              >
                <IconTrash size={ICON_SIZE} title="Delete Track" />
              </ButtonElement>
            </div>
          </div>
        </div>
      </div> */}

      <div className="track__subtracks">
        <div className="track__subtrack track__subtrack--mask" />
        <div className="track__subtrack" />
      </div>
    </div>
  );
}

export default TimelineTrack;
