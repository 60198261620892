function getImageDimensions(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = () => {
      reject(new Error('getImageDimensions: There was an error loading the image.'));
    };

    img.src = url;
  });
}

export default getImageDimensions;
