/* eslint-disable no-param-reassign */
import { createClipAnimation } from '@animation/animate';
import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';
import CanvasEditFrame from '@feature/studio/canvas/CanvasEditFrame';
import CanvasMask from '@feature/studio/canvas/CanvasMask';
import useMovableAsset from '@hooks/useMovableAsset';
import { Sprite } from '@inlet/react-pixi';
import { clipsFamily } from '@store/atoms/EditState';
import { interactiveState } from '@store/atoms/InteractionState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';
import { activeClipAssetState, clipAssetState, clipState, clipVisibilityState } from '@store/selectors/EditSelectors';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

function CanvasVideoPlayer({ video, id, index }) {
  const spriteRef = useRef();
  const canvas = useRecoilValue(canvasStageState({}));
  const [asset, setAsset] = useRecoilState(clipAssetState(id));
  const clip = useRecoilValue(clipState(id));
  const { clipId: activeClipId } = useRecoilValue(activeClipAssetState);
  const setClip = useSetRecoilState(clipsFamily(id));
  const visible = useRecoilValue(clipVisibilityState(id));
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const { isPlaying: timelineIsPlaying, playhead: timelinePlayhead } = useTimelinePlaybackContext();
  const isInteractive = useRecoilValue(interactiveState);

  const videoResource = video?.baseTexture?.resource?.source;
  const assetWidth = asset?.meta?.width || videoResource.videoWidth;
  const assetHeight = asset?.meta?.height || videoResource.videoHeight;
  const isSelected = activeClipId === id;

  // @ts-ignore
  const { start, length, opacity = 1 } = clip;
  // @ts-ignore
  const { trim = 0, volume = 1 } = asset;

  // prettier-ignore
  // eslint-disable-next-line max-len
  const movableAssetConfig = { id, assetWidth, assetHeight, canvasWidth: canvas.width, canvasHeight: canvas.height, clip, setClip, asset, setAsset, spriteRef }
  const movableAsset = useMovableAsset(movableAssetConfig);
  // prettier-ignore
  // eslint-disable-next-line max-len
  const { bounds, dimensions, handlePositionGrab, handlePositionDrop, handlePositionDrag, handleScaleDrag, handleScaleDrop, handleScaleGrab, isMoving, position, scale } = movableAsset;
  // @ts-ignore
  const clipAnimation = createClipAnimation({ type: 'video', clip, asset, playhead: timelinePlayhead, movableAsset });

  useEffect(() => {
    // TODO: Can the playhead be paused if data or connection to WebGL Is delayed or lost?
    // TODO: currentTime can take half a second to seek, how to control this when we leave and return to the preview tab?
    if (!videoResource) {
      return;
    }

    if (!visible && videoResource.currentTime !== trim) {
      setVideoIsPlaying(false);
      videoResource.currentTime = trim;
      return;
    }

    if (visible && !timelineIsPlaying) {
      setVideoIsPlaying(false);
      try {
        videoResource.currentTime = timelinePlayhead - start + trim;
      } catch (error) {
        console.error(error);
      }
      return;
    }

    if (visible && timelineIsPlaying && !videoIsPlaying) {
      setVideoIsPlaying(true);
      videoResource.play().catch(() => {
        videoResource.pause();
        setVideoIsPlaying(false);

        // todo handle error
        // error not being caught by the promise is being caused by the video being paused before it can play
        // it's thrown when the timeline ends on a video. It's not causing any issues, but it's not ideal
        // console.error(error);
      });
    }
  }, [timelinePlayhead, timelineIsPlaying, visible, start, length, trim, videoIsPlaying, videoResource]);

  useEffect(() => {
    if (!videoResource) {
      return;
    }

    videoResource.volume = volume;
  }, [videoResource, volume]);

  if (!video) {
    return null;
  }

  const contents = (
    <>
      <Sprite
        alpha={opacity}
        anchor={0.5}
        width={dimensions.width}
        height={dimensions.height}
        ref={spriteRef}
        texture={video}
        visible={visible}
        zIndex={index}
        x={position.x}
        y={position.y}
        {...clipAnimation}
      />
      {isInteractive && (
        <CanvasEditFrame
          bounds={bounds}
          width={dimensions.width}
          height={dimensions.height}
          isDragging={isMoving}
          handlePositionGrab={handlePositionGrab}
          handlePositionDrag={handlePositionDrag}
          handlePositionDrop={handlePositionDrop}
          handleScaleDrag={handleScaleDrag}
          handleScaleDrop={handleScaleDrop}
          handleScaleGrab={handleScaleGrab}
          inView={visible}
          selected={isSelected}
          scale={scale}
          zIndex={index}
        />
      )}
    </>
  );

  return (
    <CanvasMask x={position.x} y={position.y} width={dimensions.width + 1} height={dimensions.height + 1} clipId={id}>
      {contents}
    </CanvasMask>
  );
}

export default CanvasVideoPlayer;
