import { Container, Sprite } from '@inlet/react-pixi';
import { spriteMaskFragment } from '@shaders/spriteMask';
import { SpriteMaskFilter, Texture, VideoResource } from 'pixi.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { clipMaskState } from '@store/selectors/MaskSelectors';

import { handleVideoError, initializeVideo, pauseVideo, seekVideo } from '@utils/texture/video';

const DEFAULT_TRIM_TIME = 0;
const DEFAULT_VOLUME = 1;

/**
 * @typedef {[import('pixi.js').Texture, React.Dispatch<import('pixi.js').Texture> | null]} TextureState
 * @typedef {[import('pixi.js').SpriteMaskFilter, React.Dispatch<import('pixi.js').SpriteMaskFilter> | null]} SpriteMaskFilterState
 * @typedef {import('react').MutableRefObject<import('pixi.js').Sprite | undefined>} SpriteRef
 * @typedef {import('pixi.js').Texture<import('pixi.js').VideoResource>} VideoTexture
 */

/**
 * @typedef {{
 *  x: number;
 *  y: number;
 *  width?: number;
 *  height?: number;
 * }} MaskProperties
 */

/**
 * @typedef {import('react').PropsWithChildren & MaskProperties & {
 *  clipId: string;
 * }} Props
 *
 * @type {(props: Props) => JSX.Element}
 */
function CanvasMask(props) {
  const { isPlaying: timelineIsPlaying, playhead } = useTimelinePlaybackContext();

  const { x, y, width, height, clipId, children } = props;
  const mask = useRecoilValue(clipMaskState(clipId));

  const { start } = mask?.info || {};
  const { src, trim = DEFAULT_TRIM_TIME, volume = DEFAULT_VOLUME } = mask?.asset || {};

  /** @type {TextureState} */
  const [texture, setTexture] = useState(null);

  /** @type {SpriteMaskFilterState} */
  const [spriteMaskFilter, setSpriteMaskFilter] = useState(null);

  /** @type {SpriteRef} */
  const spriteRef = useRef();
  const sprite = spriteRef.current;

  const loadTexture = useCallback(async () => {
    if (!src) {
      setTexture(null);
      return;
    }

    let loadedTexture;

    try {
      loadedTexture = await Texture.fromURL(src);
    } catch (error) {
      console.error(`Failed to load texture.`, error);
    }

    if (!loadedTexture) {
      setTexture(null);
      return;
    }

    setTexture(loadedTexture);

    if (loadedTexture.baseTexture.resource instanceof VideoResource) {
      /** @ts-ignore @type {VideoTexture} */
      const videoTexture = loadedTexture;
      const video = videoTexture.baseTexture.resource.source;

      video.onloadeddata = () => initializeVideo(videoTexture, { trim, volume });
      video.onseeked = () => pauseVideo(videoTexture);
      video.onerror = () => handleVideoError(videoTexture);
    }
  }, [src, trim, volume]);

  useEffect(() => {
    loadTexture();
  }, [src, loadTexture]);

  useEffect(() => {
    if (!src) {
      setSpriteMaskFilter(null);
      return;
    }

    const filter = new SpriteMaskFilter(null, spriteMaskFragment);
    if (sprite) filter.maskSprite = sprite;

    setSpriteMaskFilter(filter);
  }, [src, sprite]);

  useEffect(() => {
    if (!texture) return;

    if (texture.baseTexture.resource instanceof VideoResource) {
      const seek = playhead - start + trim;

      /** @ts-ignore @type {VideoTexture} */
      const videoTexture = texture;
      seekVideo(videoTexture, seek, trim, mask?.visible, timelineIsPlaying);
    }
  }, [timelineIsPlaying, start, playhead, texture, trim, mask, mask?.visible]);

  const maskActive = mask && texture && spriteMaskFilter;
  const spriteWidth = maskActive ? width || texture?.width : 0;
  const spriteHeight = maskActive ? height || texture?.height : 0;
  const alpha = maskActive ? 1 : 0;

  return (
    // @ts-ignore
    <Container pivot={0.5} anchor={0.5} filters={maskActive ? [spriteMaskFilter] : []}>
      <Sprite
        x={x}
        y={y}
        anchor={0.5}
        ref={spriteRef}
        alpha={alpha}
        width={spriteWidth}
        height={spriteHeight}
        texture={texture || Texture.EMPTY}
      />
      {children}
    </Container>
  );
}

export default CanvasMask;
