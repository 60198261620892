import { memo, useRef, useState } from 'react';
import { ButtonGroup, Overlay, Popover } from 'react-bootstrap';

import { IconMore } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

function TimelineClipMore({ handleDeleteClip }) {
  const documentRef = useRef(document);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const togglePopover = (event) => {
    setShow(!show);
    setTarget(show ? null : event.target);
  };

  return (
    <>
      <ButtonElement
        title="Manage Clip"
        className="clip__more unstyled"
        handleEvent={togglePopover}
        variant="light"
        type="button"
      >
        <IconMore />
      </ButtonElement>

      <Overlay
        show={show}
        target={target}
        placement="top"
        container={documentRef.current?.body}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="popover-controls popover-clip-more">
          <Popover.Body>
            <ButtonGroup>
              <ButtonElement handleEvent={togglePopover} variant="light">
                Cancel
              </ButtonElement>
              <ButtonElement handleEvent={handleDeleteClip} variant="danger">
                Delete
              </ButtonElement>
            </ButtonGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default memo(TimelineClipMore);
