import cn from 'classnames';
import { memo } from 'react';
import { Spinner } from 'react-bootstrap';

import { IconVideo } from '@assets/icons';

import TimelineClipSprite from '@feature/studio/timeline/TimelineClipSprite';

import useCaptureFrames from '@hooks/useCaptureFrames';

function TimelineClipVideo({ clipType, asset, maxWidth }) {
  const { frames, loadingFrames } = useCaptureFrames({ asset });

  return (
    <div className={cn('clip__inner', { loading: loadingFrames })}>
      <div className="clip__header">
        <span className="clip__icon">
          {!loadingFrames ? <IconVideo size={12} /> : <Spinner animation="border" variant="light" />}
        </span>
        <span className="clip__type">{clipType}</span>
      </div>
      <TimelineClipSprite frames={frames} width={maxWidth / frames.length} />
    </div>
  );
}

export default memo(TimelineClipVideo);
