import { atom } from 'recoil';

export const canvasState = atom({
  key: 'canvas/dimensions',
  default: {
    width: undefined,
    height: undefined,
  },
});

export const stageState = atom({
  key: 'stage/dimensions',
  default: {
    width: undefined,
    height: undefined,
    scale: 1,
  },
});
