import cn from 'classnames';
import { memo } from 'react';
import { Spinner } from 'react-bootstrap';

import { IconOverlay } from '@assets/icons';

import TimelineClipSprite from '@feature/studio/timeline/TimelineClipSprite';

import useCaptureFrames from '@hooks/useCaptureFrames';

function TimelineClipOverlay({ clipType, asset }) {
  const { frames, loadingFrames } = useCaptureFrames({ asset, fps: 10 });

  return (
    <div className={cn('clip__inner', { loading: loadingFrames })}>
      <div className="clip__header">
        <span className="clip__icon">
          {!loadingFrames ? <IconOverlay size={12} /> : <Spinner animation="border" variant="light" />}
        </span>
        <span className="clip__type">{clipType}</span>
      </div>
      <TimelineClipSprite frames={frames}>
        <div className="sprite-fill" />
      </TimelineClipSprite>
    </div>
  );
}

export default memo(TimelineClipOverlay);
