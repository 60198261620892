import { useCallback, useMemo } from 'react';
import reactable from 'reactablejs';
import { useRecoilState, useRecoilValue } from 'recoil';

import TimelineClip from '@feature/studio/timeline/TimelineClip';

import { activeClipState, clipTypesFamily } from '@store/atoms/ClipState';
import { trackVisibleState } from '@store/atoms/EditState';
import { idState } from '@store/atoms/TemplateState';
import { timelineScaleState, useDeleteClipState } from '@store/atoms/TimelineState';
import { clipAssetState, clipState, clipTrackState } from '@store/selectors/EditSelectors';

import {
  TRACK_HEIGHT,
  TRACK_PADDING,
  TRACK_SUBTRACK_MASK_HEIGHT,
  TRACK_SUBTRACK_MASK_OFFSET,
} from '@constants/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';
import getTransformStyleValues from '@utils/getTransformStyleValues';
import { isNumber } from '@utils/isNumber';
import roundToPrecision from '@utils/math/roundToPrecision';

const draggableOptions = {
  inertia: false,
  autoScroll: true,
};

const resizableOptions = {
  edges: { left: true, right: true, bottom: false, top: false },
  inertia: false,
};

const ReactableClipComponent = reactable(TimelineClip);

const yAxisPixels = (trackIndex, clipType) => {
  const trackOffset = trackIndex * TRACK_HEIGHT + TRACK_PADDING;
  const clipOffset = clipType !== 'mask' ? TRACK_SUBTRACK_MASK_HEIGHT : TRACK_SUBTRACK_MASK_OFFSET;
  return trackOffset + clipOffset;
};

function TimelineClipReactable({ id }) {
  const [clip, setClip] = useRecoilState(clipState(id));
  const [asset, setAsset] = useRecoilState(clipAssetState(id));
  const { trackId, trackIndex } = useRecoilValue(clipTrackState(id));
  const isTrackVisible = useRecoilValue(trackVisibleState(trackId));
  const [activeClip, setActiveClip] = useRecoilState(activeClipState);
  const timelineScale = useRecoilValue(timelineScaleState);
  const templateId = useRecoilValue(idState);
  const clipType = useRecoilValue(clipTypesFamily(id));
  const deleteClip = useDeleteClipState();
  const pixelSeconds = getPixelSeconds(timelineScale);
  const leftEdgeTrimEnabled = ['video', 'audio'].includes(clipType);

  const tracking = useMemo(
    () => ({
      properties: {
        Id: templateId,
        'Clip Type': clipType,
        'Clip Id': id,
      },
    }),
    [clipType, id, templateId]
  );

  const getNewLength = useCallback(
    (length) => {
      const newLength = roundToPrecision(length);
      const maxLength = clipType !== 'overlay' ? asset?.meta?.duration : Infinity;
      const minLength = 0.01;
      if (newLength >= maxLength) {
        return maxLength;
      }
      if (newLength <= minLength) {
        return minLength;
      }
      return newLength;
    },
    [asset?.meta, clipType]
  );

  const handleDeleteClip = useCallback(
    (event) => {
      event.stopPropagation();
      deleteClip(id);
    },
    [deleteClip, id, tracking]
  );

  const handleResizeClipLeft = useCallback(
    ({ dir, delta }) => {
      if (dir === 'left' && clip.start > 0) {
        const newLength = getNewLength(clip.length + delta);
        if (newLength === clip.length) {
          return;
        }
        if (leftEdgeTrimEnabled) {
          setAsset({
            trim: asset.trim >= 0 ? roundToPrecision(asset.trim - delta) : 0,
          });
        }
        setClip({
          start: roundToPrecision(clip.start - delta),
          length: newLength,
        });
      } else if (dir === 'right') {
        const newLength = getNewLength(clip.length - delta);
        if (newLength === clip.length) {
          return;
        }
        if (leftEdgeTrimEnabled) {
          setAsset({
            trim: asset.trim >= 0 ? roundToPrecision(asset.trim + delta) : 0,
          });
        }
        setClip({
          start: roundToPrecision(clip.start + delta),
          length: newLength,
        });
      }
    },
    [asset.trim, clip.length, clip.start, getNewLength, leftEdgeTrimEnabled, setAsset, setClip]
  );

  const handleResizeClipRight = useCallback(
    ({ dir, delta }) => {
      if (dir === 'left') {
        const newLength = getNewLength(clip.length - delta);
        if (newLength === clip.length) {
          return;
        }
        setClip({
          length: newLength,
        });
      } else if (dir === 'right') {
        const newLength = getNewLength(clip.length + delta);
        if (newLength === clip.length) {
          return;
        }
        setClip({
          length: newLength,
        });
      }
    },
    [clip.length, getNewLength, setClip]
  );

  const onDragMove = useCallback((event) => {
    const { dx, dy, target: draggableElement } = event;
    const { x: clipX, y: clipY } = getTransformStyleValues(draggableElement.style.transform);
    const x = roundToPrecision(clipX + dx);
    const y = roundToPrecision(clipY + dy);

    if (!isNumber(x) || !isNumber(y)) {
      return;
    }

    draggableElement.style.transform = `translate(${x}px, ${y}px)`;
  }, []);

  const onDragEnd = useCallback(
    (event) => {
      const { dx, target: draggableElement } = event;
      const { x: clipX } = getTransformStyleValues(draggableElement.style.transform);
      const x = roundToPrecision(clipX + dx);
      const y = yAxisPixels(trackIndex, clipType);

      if (!isNumber(x) || !isNumber(y)) {
        return;
      }

      draggableElement.style.transform = `translate(${x}px, ${y}px)`;
    },
    [clipType, trackIndex]
  );

  const onResizeMove = useCallback(
    (event) => {
      const { edges, velocity, dx } = event;
      const { left: leftEdge, right: rightEdge } = edges;
      const dir = velocity.x < 0 ? 'left' : 'right';
      const delta = Math.abs(dx) / timelineScale;

      if (leftEdge) {
        handleResizeClipLeft({ dir, delta });
      } else if (rightEdge) {
        handleResizeClipRight({ dir, delta });
      }
    },
    [timelineScale, handleResizeClipLeft, handleResizeClipRight]
  );

  const onDown = useCallback(
    (event) => {
      event.preventDefault();
      setActiveClip(id);
    },
    [id, setActiveClip]
  );

  return (
    <ReactableClipComponent
      draggable={draggableOptions}
      resizable={resizableOptions}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      onResizeMove={onResizeMove}
      onDown={onDown}
      id={id}
      clip={clip}
      clipType={clipType}
      asset={asset}
      trackId={trackId}
      isVisible={isTrackVisible}
      activeClip={activeClip === id}
      handleDeleteClip={handleDeleteClip}
      x={pixelSeconds(clip.start)}
      y={yAxisPixels(trackIndex, clipType)}
      width={pixelSeconds(clip.length)}
      maxWidth={pixelSeconds(asset?.meta?.duration)}
    />
  );
}

export default TimelineClipReactable;
